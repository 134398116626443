import React, {useEffect, useState, useRef} from 'react'
import moment from 'moment'
import { useNavigate, useLocation } from "react-router-dom"

import {getOrderDetail} from '../../Api/Transaction'
import {registeredTeam} from '../../Api/RegistrationSport';
import {priceSplitter, checkUserToken} from '../../Tools/Tools'

function DetailOrder(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState([])

  const orderId = location.state.orderId
  const backPage = location.state.backPage
  
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const [orderDetail, setOrderDetail] = useState([])

  const [team, setTeam] = useState([])
  const [personilTeam, setPersonilTeam] = useState([])
  const [personilView, setPersonilView] = useState([])

  const itemDivVisitor = useRef(null)

  const reqOrderDetail = async () => {
    getOrderDetail(orderId).then((res) => {
      if(res.status === 200){
        setOrderDetail(res.data)
        setImageUrl(res.data.product_name === '1' ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (res.data.product_name === '2' ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
        
        if(res.data.ticket_type === 'Peserta'){
          reqRegTeam(res.data.registration_id)
        }else{
          setLoaderDisplay('none')
        }
      }
    })
  }

  const reqRegTeam = async (idRegistration) => {
    registeredTeam(idRegistration).then((res) => {
      if(res.status == 200){
        console.log(res.data)
        setTeam(res.data.team)
        setPersonilTeam(res.data.personil)
        setLoaderDisplay('none')
      }
    })
  }

  const paymentHandler = () => {
    if(orderDetail.payment_method === 'VA'){
      navigate('/payment', {state: { 
                                      accountNumber : orderDetail.va_number,
                                      bankCode : orderDetail.bank,
                                      expectedAmount : orderDetail.amount,
                                      expirationDate : orderDetail.expiration_date,
                                      event_name : orderDetail.event_name,
                                      ticket_name : orderDetail.ticket_name,
                                      topik_name :orderDetail.topik_name
                                    }})
    }else if(orderDetail.payment_method === 'QRIS'){
      navigate('/payment-qris', {state: {
                                          qr_id : orderDetail.third_party_id,
                                          qr_string : orderDetail.qr_string,
                                          expectedAmount : orderDetail.amount,
                                          event_name : orderDetail.event_name,
                                          ticket_name : orderDetail.ticket_name,
                                          topik_name :orderDetail.topik_name
                                        }})
    }else if(orderDetail.payment_method === 'E-Wallet'){
      navigate('/payment-ewallet', {state: {
                                              expectedAmount : orderDetail.amount,
                                              desktopWebUrl : orderDetail.desktop_web_checkout_url,
                                              mobileWebUrl : orderDetail.mobile_web_checkout_url,
                                              mobileDeeplinkUrl : orderDetail.mobile_deeplink_checkout_url,
                                              channelCode : orderDetail.bank.replace('ID_', ''),
                                              event_name : orderDetail.event_name,
                                              ticket_name : orderDetail.ticket_name,
                                              topik_name :orderDetail.topik_name
                                            }})
    }
  }

  const viewPersonil = () => {
    if(personilTeam.length !== 0){
      setPersonilView(
        personilTeam.map((person, personKey) => {
          return (
            <div className="emPage__invoiceDetails px-1 py-0">
              <div className="emfooter__invoice mt-3">
                <div className="emBox__paymentDetails p-2">
                  <span className="weight-600 d-block text-left mb-2">Anggota #{personKey+1}</span>
                  <ul>
                    <li>
                      <span>Nama Lengkap</span>
                      <span>{personilTeam[personKey].fullname}</span>
                    </li>
                    <li>
                      <span>Tanggal Lahir</span>
                      <span>{moment(personilTeam[personKey].dob).format('DD MMM YYYY')}</span>
                    </li>
                    <li>
                      <span>Usia</span>
                      <span>{moment().diff(personilTeam[personKey].dob, 'years')} Tahun</span>
                    </li>
                    <li>
                      <span>Kartu Identitas</span>
                      <a href="#" target="_blank">{personilTeam[personKey].card_id}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
        }) 
      )
    }
  }

  useEffect(() => {
    viewPersonil()
  }, [personilTeam])
  
  const toEticket=(id)=>{
    navigate('/eticket',{state:{orderId:id}});
  }

  useEffect(() => {
    if(!checkUserToken()){
      navigate('/login')
    }else{
      reqOrderDetail()
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="overflow-auto">
        {/* Start main_haeder */}
        <header className="header_tab shadow-sm">
          <div className="main_haeder multi_item">
            <div className="em_side_right">
              <a
                className="rounded-circle d-flex align-items-center text-decoration-none"
                href={'/'+backPage}
              >
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">Back</span>
              </a>
            </div>
            <div className="title_page">
              <span className="page_name">Detail Pesanan</span>
            </div>
          </div>
        </header>
        {/* End.main_haeder */}
        <section className="emPage__invoiceDetails margin-b-70">
          <div className="emhead__invoice">
            <div className="brand__id">
              <div className="brand">
                <img
                  src={imageUrl+orderDetail.product+'/'+orderDetail.foto_banner}
                  height={'50px'}
                  width={'auto'}
                  alt=""
                />
              </div>
              <div className="date_ticket">
                <span className="id color-secondary">#{orderDetail.transaction_number}</span>
                <span className="date color-text">{orderDetail.order_date}</span>
              </div>
            </div>
            <div className="w-100 pt-2 d-flex justify-content-between align-items-center">
              <span className="color-comment">Status</span>
              {
                orderDetail.status === 'PENDING' ? (
                  <span className="color-yellow border border-yellow rounded-8 padding-3 size-11 px-2">Menunggu Pembayaran</span>
                ) : (orderDetail.status === 'COMPLETED' ? (
                    <span className="color-green border border-green rounded-8 padding-3 size-11 px-2">Telah Dibayarkan</span>
                  ) : (orderDetail.status === 'ARCHIVED' ? (
                    <span className="color-grey border border-grey rounded-8 padding-3 size-11 px-2">Event Selesai</span>
                  ) : (
                    <span className="bg-lightgrey border border-grey rounded-8 padding-3 size-11 px-2">Pembayaran Expired</span>
                  )
                )
                )
              }
            </div>
            <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
              <span className="color-comment">Metode Pembayaran</span>
              <span className="">{orderDetail.payment_method === 'VA' ? 'Virtual Account - '+orderDetail.bank : orderDetail.payment_method} - {orderDetail.bank}</span>
            </div>
            {
              orderDetail.status === 'PENDING' ? (
                <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
                  <span className="color-comment">Bayar Sebelum</span>
                  <span className="">{orderDetail.expiration_date}</span>
                </div>
              ) : (orderDetail.status === 'COMPLETED' ? (
                <div className="w-100 pt-1 d-flex justify-content-between align-items-center">
                  <span className="color-comment">Tanggal Pembayaran</span>
                  <span className="">{orderDetail.transaction_time}</span>
                </div>
                ) : ('')
              )
            }
          </div>
          <div className="embody__invoice">
            <div className="about__sent">
              <div className="billed__to size-14">
                <h2>Tiket {orderDetail.product_name}:</h2>
                <p className="username size-14">{orderDetail.event_name} - {orderDetail.ticket_name}</p>
                <div className="card-body pt-0 pb-2 px-0">
                  <div className="mt-1 size-14">
                    <p className="d-inline-block size-14">
                      <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                      </svg>
                      {orderDetail.location_name+', '+orderDetail.address+', '+orderDetail.kota+', '+orderDetail.province}
                    </p>
                  </div>
                </div>
                <div className="w-100 pt-1 d-flex justify-content-between">
                  <span className="color-comment">Tanggal {orderDetail.product_name} Dipilih</span>
                  <span className="color-blue border border-blue rounded-8 padding-3 size-12 px-2">{orderDetail.selection_date}</span>
                </div>
              </div>
            </div>
            <div className="about__sent border rounded-10 my-3 p-2 shadow-sm">
              <div className="billed__to size-14 w-100">
                <h2>Detail {orderDetail.ticket_type === 'Peserta' ? 'Pendaftar' : 'Pemesan'}:</h2>
                <span className="d-block font-weight-bold text-capitalize">{orderDetail.customer_name}</span>
                <span className="d-block color-text">{orderDetail.customer_email}</span>
                <span className="d-block color-text">{orderDetail.customer_phone}</span>
              </div>
            </div>
            {
              orderDetail.ticket_type === 'Peserta' ? (
              <div className="about__sent border rounded-10 my-3 p-2 shadow-sm">
                <div className="billed__to size-14 w-100">
                  <h2>Detail Tim :</h2>
                  <div className="itemSwitch d-flex">
                    <label htmlFor="toggleTwo">
                      <span className="size-14">Nama Tim</span>
                    </label>
                    <div className="em_toggle ml-auto">
                      <span className="size-14 weight-600">{team.name_team}</span>
                    </div>
                  </div>
                  <div className="itemSwitch d-flex border-bottom pb-1">
                    <label htmlFor="toggleTwo">
                      <span className="size-14">Jumlah Anggota</span>
                    </label>
                    <div className="em_toggle ml-auto">
                      <span className="size-14 weight-600">{team.count_peserta} Orang</span>
                    </div>
                  </div>
                  <div id="headingOne-inset">
                    <div className="nav-link main_item d-flex px-0 py-2" data-toggle="modal" data-target="#mdl_personil">
                      <div className="media align-items-center">
                        <div className="media-body my-auto">
                          <div className="size-14" style={{'cursor' : 'default'}}>
                            <span className="mb-0 color-primary weight-600 text-capitalize">Detail Anggota</span>
                          </div>
                        </div>
                      </div>
                      <div className="side_right ml-auto">
                        <i className="tio-chevron_right -arrwo"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ) : (
                <div className="about__sent border rounded-10 my-3 p-2 shadow-sm">
                  <div className="billed__to size-14 w-100">
                    <h2>Detail Pengunjung:</h2>
                    <span className="d-block font-weight-bold text-capitalize">{orderDetail.visitor_name}</span>
                    <span className="d-block color-text">{orderDetail.visitor_email}</span>
                    <span className="d-block color-text">{orderDetail.visitor_phone}</span>
                  </div>
                </div>
              )
            }
            <div className="emtable__Details">
              <h2 className="size-14">Rincian Pembayaran:</h2>
              <div className="emPage__basket npPkg__basket default py-0">
                <div className="detailsTotally p-0">
                  <ul>
                    {/* <div className="dividar my-2" /> */}
                    <li className="mb-0 size-14">
                      <span>Jumlah Tiket</span>
                      <span className="font-weight-normal">{orderDetail.qty} Pax</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Harga /tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter(orderDetail.price)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Diskon</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(orderDetail.discount_amount)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Total Diskon</span>
                      <span className="font-weight-normal">Rp {priceSplitter(orderDetail.discount_amount*orderDetail.qty)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Total Harga Tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter(orderDetail.price*orderDetail.qty)}</span>
                    </li>
                    <div className="dividar my-2" />
                    <li className="mb-0 size-14">
                      <span>
                        Pajak 
                      </span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(orderDetail.tax)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Biaya Asuransi</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(orderDetail.insurance)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Potongan Kode Promo</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(orderDetail.promo_amount)}</span>
                    </li>
                    <li className="mb-0 size-14">
                      <span>Potongan Poinmu</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(orderDetail.use_poin)}</span>
                    </li>
                    <div className="dividar my-2" />
                    <li className="mb-1 size-14">
                      <span className=" text_total">Total</span>
                      <span className="number_total">Rp {priceSplitter(orderDetail.amount)}</span>
                    </li>
                    <li className=" d-flex justify-content-end text-end">
                      <span className="color-text size-12 mt-0">
                        <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={14} alt="" className="mr-1 margin-b-9"/>
                        Poin
                        <span className="ml-1 text-primary size-12 d-inline">{orderDetail.received_poin}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          {
            orderDetail.status === 'PENDING' ? (
              <div className="size-16 weight-600 color-primary mb-2" style={{'cursor' : 'default'}}>
                <span className="color-text">Rp</span> {priceSplitter(orderDetail.amount)}
              </div>
            ) : ''
          }
          <div className="env-pb text-center">
            {
              orderDetail.status === 'PENDING' ? (
                <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14" onClick={() => paymentHandler()}>
                  <b>Selesaikan pembayaran</b>
                </button>
              ) : (orderDetail.status === 'COMPLETED' ? (
                <button onClick={() => toEticket(orderDetail.uuid)} type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5 size-14">
                  <b>E-Ticket</b>
                </button>
                ) : (
                  <a href="/home" type="button" className="btn btn-secondary w-100 color-white text-center justify-content-center px-5 size-14">
                    <b>Home</b>
                  </a>
                  )
              )
            }
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_personil" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Detail Anggota</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body px-3 overflow-auto pb-3" style={{height : '75vh'}}>
              {personilView}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default DetailOrder