import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 
              'Authorization': `Bearer ${token}`,
              // 'Content-Type': 'application/json', 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "content-type",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH"
            }
}

const configUpload = {
                      headers: { 
                                  'Authorization': `Bearer ${token}`,
                                  // 'Content-Type': 'application/json', 
                                  "Access-Control-Allow-Origin": "*",
                                  "Access-Control-Allow-Credentials": "true",
                                  "Access-Control-Max-Age": "1800",
                                  "Access-Control-Allow-Headers": "content-type",
                                  "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
                                  "Content-Type" : "multipart/form-data"
                                }
                      }

const configNoAuth = {
  headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "false",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const registration = async (formData) => {
  const reqRegistration = await axios.post(`${baseUrl}/registration-sport`, formData, config)
  return reqRegistration
}

export const registrationPersonil = async (formData) => {
  const reqRegistration = await axios.post(`${baseUrl}/registration-sport-personil`, formData, configUpload)
  return reqRegistration
}

export const registeredTeam = async (idRegistration) => {
  const reqRegistration = await axios.get(`${baseUrl}/get-team-registered/${idRegistration}`, configUpload)
  return reqRegistration
}