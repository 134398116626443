import React, {Component} from 'react';

class SearchBar extends Component{
  render(){
    return (
      <div>
        <section className="searchMenu__hdr">
          <form>
            <div className="form-group">
              <div className="input_group">
                <input type="search" className="form-control" placeholder="type something here..." />
                <i className="ri-search-2-line icon_serach" />
              </div>
            </div>
          </form>
          <button type="button" className="btn btn_meunSearch -close __removeMenu">
            <i className="tio-clear" />
          </button>
        </section>
      </div>
    );
  }
}
export default SearchBar;