import React, {useEffect} from 'react';

function SplashScreen(){
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    
    setTimeout(function() { 
      window.location.replace('/home');
    }, 2000)
  })

  return (
    <div className="d-flex align-items-center text-center background-img" style={{'height' : '100vh'}}>
    {/* <div className="d-flex align-items-center text-center" style={{'height' : '100vh', 'background-color' : 'rgb(136,33,246)'}}> */}
      <div className="spalsh__screen mx-auto">
        <div className="logo p-3" style={{marginTop : '-8vh', borderRadius : '40px', 'background-color' : ''}}>
          <img src="assets/juara-tiket.png" width={'auto'} height={80} alt="" className="bounce bounce2"/>
          {/* <img src="assets/logo-small.png" width={'auto'} height={80} alt="" className=""/> */}
          {/* <h2>Juara Tiket</h2> */}
        </div>
        <div className="env-pb d-flex justify-content-center">
          <p className="absolute bottom-0 mb-0 size-11 color-text padding-b-20 text-white">
            Copyright © Juara Tiket 2024
          </p>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen;