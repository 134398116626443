import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" }
}

export const bankList = async () => {
  const req = await axios.get(`${baseUrl}/master/bank`, config)
  return req.data
}

export const cityList = async () => {
  const req = await axios.get(`${baseUrl}/master/city`, config)
  return req.data
}

export const provinceList = async () => {
  const req = await axios.get(`${baseUrl}/master/province`, config)
  return req
}