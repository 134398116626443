import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {updatePassword} from '../../Api/Auth'

function ChangePassword(){
  const navigate = useNavigate()
  const userId = localStorage.getItem('user-id')

  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")
  const [validation, setValidation] = useState("")

  const inputRef = useRef(null);
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  const updatePassHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('current_password', password);
      formData.append('new_password', newPassword);
      formData.append('new_password_confirmation', confirmPassword);
      updatePassword(formData).then((res) => {
        console.log(res)
        if(res.responseCode === 200000){
          // setValidation(res.message)
          setTimeout(function() { 
            setValidation('')
            setPassword('')
            setNewPassword('')
            setConfirmPassword('')
            navigate('/profile')
          }, 1000)
        }else{
          setValidation(res.responseMessage)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  useEffect(() => {
    checkUserToken()
    inputRef.current.focus()
  },[]);

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/profile">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Change Password
            </span>
          </div>
          {/* <div className="em_side_right">
            <a href="/home" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all text-primary">
              Home
            </a>
          </div> */}
        </header>
        {/* form */}
        <section className="padding-20">
          <form onSubmit={updatePassHandler} className="padding-t-70">
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input ref={inputRef} type="password" className="form-control" placeholder="enter current password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label>Password</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed" id="show_hide_password">
                <div className="input_group">
                  <input type="password" id="pass" className="form-control" placeholder="enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label htmlFor="pass">New Password</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input type="password" className="form-control" placeholder="confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label>Confirm New Password</label>
              </div>
            </div>
            <div className="alert alert-light text-center size-12 color-microsoft" role="alert">
              {
                validation !== "" ? (<div>{validation}</div>) : ''
              }
            </div>
            <div className="buttons__footer text-center bg-transparent">
              <div className="env-pb">
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Save Changes</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </section>
        {/* buttons__footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default ChangePassword;