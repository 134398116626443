import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom"
import moment from 'moment'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'

import {productDetail} from '../../Api/Product'
import {priceSplitter, checkUserToken} from '../../Tools/Tools'

function ProductDetail(){
  const location = useLocation()
  const navigate = useNavigate()
  const {slug} = useParams()
  const [businessType, setBusinessType] = useState(location.state.businessType)
  const [imageUrl, setImageUrl] = useState([])

  const id = useRef()
  const productCategory = useRef()
  const dateToday = useRef(moment().format('Y-MM-DD'))
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const userFullname = localStorage.getItem('fullname') ===  null ? '' : localStorage.getItem('fullname')
  const selectTicket = useRef()

  const orderName = useRef(userFullname)
  const [dataOrder, setDataOrder] = useState([])
  const [productData, setProductData] = useState([])
  const [productTicket, setProductTicket] = useState([])
  const [dateList, setDateList] = useState([])
  const [qtyTicket, setQtyTicket] = useState(1)
  const [selectDate, setSelectDate] = useState(1)
  const [alertZero, setAlertZero] = useState('')

  const [imageBanner, setImageBanner] = useState([])

  const reqDetail = async () => {
    productDetail(slug).then((res) => {
      if(res.status == 200){
        setLoaderDisplay('none')
        const arr = []
        res.data.ticket.map((ticket, Key) => {
          var disc = 0
          var priceAfterDisc = res.data.ticket[Key].ticket_price_bayar-disc
          var start_date_discount = res.data.ticket[Key].start_date_discount === '' ? '1970-01-01' : res.data.ticket[Key].start_date_discount
          var start_end_discount = res.data.ticket[Key].end_date_discount === '' ? '1970-01-01' : res.data.ticket[Key].end_date_discount
          var discStart = new Date(start_date_discount)
          var discEnd = new Date(start_end_discount)

          if(
            res.data.ticket[Key].discount === 'Ya'
            && moment().isAfter(discStart)
            && moment().isBefore(discEnd)
          ){
            if(res.data.ticket[Key].discount_type === 'procentage'){
              disc = (res.data.ticket[Key].discount_procentage/100)*res.data.ticket[Key].ticket_price_bayar
            }else{
              disc = res.data.ticket[Key].discount_rp
            }

            priceAfterDisc = res.data.ticket[Key].ticket_price_bayar-disc
          }

          var arr2 = []
          if(res.data.ticket[Key].ticket_type == 'Peserta'){
            arr2 = [{
                      'idTicket' : res.data.ticket[Key]['id_product_event_ticket'],
                      'quantity' : 1,
                      'disc' : disc,
                      'discStart' : discStart,
                      'discEnd' : discEnd,
                      'priceAfterDisc' : priceAfterDisc,
                      'selectDate' : '',
                      'poinReward' : res.data.ticket[Key]['poin_reward'],
                      'alertZero' : ''
                    }]
          }else{
            arr2 = [{
                      'idTicket' : res.data.ticket[Key]['id_product_event_ticket'],
                      'quantity' : 0,
                      'disc' : disc,
                      'discStart' : discStart,
                      'discEnd' : discEnd,
                      'priceAfterDisc' : priceAfterDisc,
                      'selectDate' : '',
                      'poinReward' : res.data.ticket[Key]['poin_reward'],
                      'alertZero' : ''
                    }]
          }

          arr.push(arr2)
          setDataOrder([...arr])
        })

        id.current = res.data.event.id_event
        productCategory.current = res.data.event.product_name

        if(res.data.event.foto_banner.includes(",")){
          setImageBanner(res.data.event.foto_banner.split(','))
        }

        // console.log(res.data.event.product_name)
        setImageUrl(res.data.event.product_name === 1 ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (res.data.event.product_name === 2 ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
        setProductData(res.data.event)
        setProductTicket(res.data.ticket)
      }
    })
  }

  const qtyHandler = (key, operator) => {
    if(operator === 'up'){
      setQtyTicket(qtyTicket+1)
      dataOrder[key][0].quantity = dataOrder[key][0].quantity+1
    }else if(operator === 'down' && dataOrder[key][0].quantity > 0){
      setQtyTicket(qtyTicket-1)
      dataOrder[key][0].quantity = dataOrder[key][0].quantity-1
    }
  }

  const selectDateHandler = (key, date) => {
    setAlertZero('')
    dataOrder[key][0].alertZero = ''
    setSelectDate(date)
    dataOrder[key][0].selectDate = date
  }

  const selectDateChange = (key, date) => {
    setAlertZero('')
    // alert(date.target.value)
    dataOrder[key][0].alertZero = ''
    setSelectDate(date.target.value)
    dataOrder[key][0].selectDate = date.target.value
  }

  const orderHandle = (key) => {
    if(!checkUserToken()){
      navigate('/login')
    }else{
      dataOrder[key][0].alertZero = ''
      if(dataOrder[key][0].selectDate === ''){
        setAlertZero('Pilih tanggal event terlebih dahulu.')
        dataOrder[key][0].alertZero = 'Pilih tanggal event terlebih dahulu.'
      }else{
        if(dataOrder[key][0].quantity === 0){
          setAlertZero('Tambah jumlah tiket terlebih dahulu.')
          dataOrder[key][0].alertZero = 'Tambah jumlah tiket terlebih dahulu.'
        }else{
          setAlertZero('')
          dataOrder[key][0].alertZero = ''
          setLoaderDisplayBg0('flex')
          setTimeout(function() { 
            navigate('/checkout', { state: {
                                              qtyTicket : dataOrder[key][0].quantity, 
                                              idEvent : id.current, 
                                              slug : slug, 
                                              idTicket : dataOrder[key][0].idTicket, 
                                              orderName : orderName.current, 
                                              selectionDate : dataOrder[key][0].selectDate
                                            }})
          }, 400)
        }
      }
      setAlertZero(key)
    }
  }

  const registerHandle = (key) => {
    if(!checkUserToken()){
      navigate('/login')
    }else{
      setAlertZero('')
      dataOrder[key][0].alertZero = ''
      setLoaderDisplayBg0('flex')
      setTimeout(function(){ 
        navigate('/register-sport', {state: {
                                          qtyTicket : dataOrder[key][0].quantity, 
                                          idEvent : id.current, 
                                          slug : slug, 
                                          idTicket : dataOrder[key][0].idTicket, 
                                          orderName : orderName.current, 
                                          selectionDate : dataOrder[key][0].selectDate
                                        } })
      }, 300)
    }
  }

  const goToTicket = () => {
    selectTicket.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const arrDate = []
    const from = new Date(Date.parse(productData.start_date))
    const to = new Date(Date.parse(productData.end_date))
        
    for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
      arrDate.push(moment(day).format('DD MMM YYYY'))
      setDateList([...arrDate])
    }
    // console.log(arrDate)
  }, [productData])

  useEffect(() => {
    reqDetail()
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 10%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item header-white">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none bg-white" href="/home">
              <i className="tio-chevron_left size-24 color-black m-1" style={{'color' : 'black'}} />
            </a>
          </div>
          <div className="title_page">
            {/* title here */}
          </div>
          <div className="em_side_right">
            <span className="rounded-circle d-flex align-items-center text-decoration-none bg-white" data-toggle="modal" data-target="#mdllButtons_share">
              <i className="ri-share-forward-box-line size-18 mx-2 my-1" style={{'color' : 'black'}}/>
            </span>
          </div>
        </header>
        <section className="embanner_SubCategories">
          <div className="em_head">
            <div className="cover_course">
              {/* <img src={imageUrl+productData.id_event+'/'+productData.foto_banner} alt="image" /> */}
              {
                imageBanner.length === 0 ? (
                  (<img src={imageUrl+productData.id_event+'/'+productData.foto_banner} alt="image" />)
                ) : (
                  <OwlCarousel 
                    items={1} 
                    loop={true}
                    autoplay={true}
                    autoplayHoverPause={true}
                    autoplayTimeout={3000}
                    dots={true}
                    className="owl-carousel owl-theme" 
                    style={{minHeight : '50px'}}>
                    {
                      imageBanner.map((image, Key) => {
                        if(Key < 5){
                          return (
                            <div className="item em_item">
                              <div className="em_cover_img rounded-0">
                                <img class="rounded-0" src={imageUrl+productData.id_event+'/'+imageBanner[Key]} alt="" />
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </OwlCarousel>
                )
              }
              <div id="dotsId"></div>
            </div>
          </div>
        </section>
        <section className="emPage__detailsCourse">
          <div className={imageBanner.length === 0 ? 'em__body mt-3' : 'em__body'}>
            <div className="title_course">
              <h1>{productData.event_name}</h1>
            </div>
            <span className="size-12 color-text d-block mb-1">
            {
              productData.product_name === '1' ? 'Jam Buka' : 'Tanggal Event'
            }
            </span>
            {
              productData.product_name !== '1' ? (
                <div>
                  <span className="d-inline-block">
                    <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                    </svg>
                    {productData.start_date === productData.end_date ? productData.start_date : productData.start_date+' - '+productData.end_date}
                  </span>
                </div>
              ) : ''
            }
            <div>
              <span className="d-inline-block">
                <svg className="w-16 h-16 text-gray-800 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {productData.start_time+' - '+productData.end_time}
              </span>
            </div>
          </div>
          <div className="dividar margin-t-20 margin-b-20"></div>
          <div className="em__requirements">
            <div className="title pb-0">
              <h3 className="size-16">Deskripsi</h3>
              <div className="pt-2 color-secondary">
                <p>
                  {productData.deskripsi}
                </p>
              </div>
            </div>
          </div>
          <div className="em__pkLink accordion" id="accordionExample5">
            <ul className="nav__list with_border fullBorder mb-3">
              <li>
                <div id="headingOne-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseOne-text" aria-expanded="true" aria-controls="collapseOne-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Highlight</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseOne-text" className="collapse show" aria-labelledby="headingOne-text" data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      <div dangerouslySetInnerHTML={{__html: productData.highlight}} />
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingTwo-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseTwo-text" aria-expanded="false" aria-controls="collapseTwo-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Syarat & Ketentuan</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseTwo-text" className="collapse" aria-labelledby="headingTwo-text" data-parent="#accordionExample5">
                  <div className="em__pkLink">
                    <div className="card-body">
                      <p className="mb-0 size-15 color-text">
                        <div dangerouslySetInnerHTML={{__html: productData.syarat_ketentuan}} />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingThree-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseThree-text" aria-expanded="false" aria-controls="collapseThree-text">
                    <div className="group">
                      <div>
                        <span className="path__name">Penukaran Tiket</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-chevron_right -arrwo" />
                    </div>
                  </div>
                </div>
                <div  id="collapseThree-text" className="collapse"  aria-labelledby="headingThree-text"  data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      <div dangerouslySetInnerHTML={{__html: productData.cara_penukaran_tiket}} />
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="em__certification">
            <div className="box">
              <div className="title">
                <h3 className="size-16">Info Penting</h3>
                <p>
                  <div dangerouslySetInnerHTML={{__html: productData.info_penting}} />
                </p>
              </div>
            </div>
          </div>
          <div className="em__requirements ">
            <div className="title pb-3">
              <h3 className="size-16">Lokasi</h3>
              <div className="">
                
                <div className="item rounded-8 border shadow-sm mt-2">
                  <div className="emhead_w p-2">
                    <div className="details_w" style={{width : '100%'}}>
                      <span className="d-block size-12 color-comment">
                        <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                        </svg>
                        {productData.location_name+', '+productData.address+', '+productData.kota+', '+productData.province}
                      </span>
                    </div>
                  </div>
                  <div className="dividar"></div>
                  <div className="embody_w p-2">
                    <div className="w-100">
                      <a href={'http://maps.google.co.uk/maps?q='+productData.longitude+','+productData.latitude} target="_blank" className="btn btn-sm bg-primary w-100 color-white text-center justify-content-center mr-2">
                        <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clipRule="evenodd" />
                        </svg>
                        Tunjukan Lokasi
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="em__requirements ">
            <div className="title pb-3">
              <h3 className="size-16">Ticket Sudah Termasuk</h3>
              <div className="">
                
                <div className="item rounded-8 border shadow-sm mt-2">
                  <div className="emhead_w pt-3 pr-2 pb-0">
                    <div className="details_w" style={{width : '100%'}}>
                      <div className="d-block size-12 color-comment">
                        <div dangerouslySetInnerHTML={{__html: productData.tiket_sudah_termasuk}} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div ref={selectTicket} className="emTitle_co padding-20 bg-snow padding-b-10">
            <h2 className="size-16 weight-500 color-secondary mb-0">
              Pilihan Tiket
            </h2>
          </div>

          {
            productData.include_registration === '1' ? (
              <div>
                <div className="tab__line two_item">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-penonton" role="tab" aria-controls="pills-home" aria-selected="true">Penonton</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-peserta" role="tab" aria-controls="pills-profile" aria-selected="false">Peserta</a>
                    </li>
                  </ul>
                </div>
                <section className="components_page">
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-penonton" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className="emPage___profile accordion accordion_inset py-0 padding-20 bg-snow pb-2 pt-3" id="accordionExample-inset">
                        <div className="emBody__navLink">
                          <ul>
                            {
                              productTicket.map((ticket, Key) => {
                                if(productTicket[Key].ticket_type === 'Penonton'){
                                  console.log(productTicket[Key].ticket_type)
                                  return (
                                    <li className="item margin-b-10" key={Key}>
                                      <div id="headingOne-inset" className="">
                                        {/* <div className="nav-link main_item d-flex flex-column" style={{borderRadius : 0}} data-toggle="collapse" data-target={'#collapseOne-inset-'+Key} aria-expanded="false" aria-controls="collapseOne-inset"> */}
                                        <div className="nav-link main_item d-flex flex-column pb-2" style={{borderRadius : 0}}>
                                          <div className="w-100 d-flex">
                                            <div className="media align-items-center">
                                              <div className="media-body my-auto">
                                                <div className="txt">
                                                  <h3 className="mb-0 align-middle">
                                                    <span className="d-inline-block p-1 size-12 weight-600">
                                                      {productTicket[Key].ticket_name}
                                                    </span>
                                                    {
                                                      productTicket[Key].discount === 'Ya' 
                                                      && moment().isAfter(dataOrder[Key][0].discStart)
                                                      && moment().isBefore(dataOrder[Key][0].discEnd)
                                                      ? productTicket[Key].discount_type === 'procentage' ? (
                                                        <span className="badge badge-primary">
                                                          Diskon {productTicket[Key].discount_procentage}%
                                                        </span>
                                                      ) : (
                                                        <span className="badge badge-primary">
                                                          Diskon Rp {priceSplitter(productTicket[Key].discount_rp)}
                                                        </span>
                                                      ) : ''
                                                    }
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="side_right ml-auto color-primary">
                                              <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                                <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div id={'collapseOne-inset-'+Key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                                        <div className="card-body bg-white pt-0">
                                          <div className="w-100 border-top pt-2">
                                            <div className="w-auto mb-1">
                                                <span className="size-12 text-dark d-block mb-1">Pilih Tanggal</span>
                                              {
                                                dateList.map((date, dateKey) => {
                                                  return (
                                                    <button 
                                                      className={
                                                        dataOrder[Key][0].selectDate === null ? 
                                                        'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                                        (
                                                          dataOrder[Key][0].selectDate === date ? 
                                                          'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                                          'btn btn-outline-primary btn-sm rounded-10 size-12 mb-2 mr-1' 
                                                        )
                                                      }
                                                      style={{padding : '2px 5px 2px 5px'}} 
                                                      onClick={() => selectDateHandler(Key, date)}
                                                    >
                                                      {dateList[dateKey]}
                                                    </button>
                                                  )
                                                })
                                              }
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <div className="d-flex flex-column">
                                                <span className="size-12 text-dark">Harga /pax</span>
                                                {
                                                  productTicket[Key].discount === 'Ya'
                                                  && moment().isAfter(dataOrder[Key][0].discStart)
                                                  && moment().isBefore(dataOrder[Key][0].discEnd)
                                                  ? 
                                                    (
                                                      <span className="size-12 color-text" style={{textDecoration : 'line-through'}}>
                                                        <span className="color-text">Rp</span> {priceSplitter(productTicket[Key].harga_tiket)}
                                                      </span>
                                                    ) : ''
                                                }
                                                <span className="size-14 weight-600 color-secondary">
                                                  <span className="color-text">Rp</span> {priceSplitter(dataOrder[Key][0].priceAfterDisc)}
                                                </span>
                                                {
                                                  productTicket[Key].kuota_ticket >= 10 ? (
                                                    <span className="size-12 color-medium">Tiket tersedia</span>
                                                  ) : productTicket[Key].kuota_ticket > 0 && productTicket[Key].kuota_ticket < 10 ? (
                                                    <span className="size-12 color-yellow">Kuota tiket tersedia dibawah 10</span>
                                                  ) : (
                                                    <span className="size-12 color-microsoft">Tiket tidak tersedia</span>
                                                  )
                                                }
                                              </div>
                                              <div className="d-flex flex-column justify-content-center ml-auto">
                                                <span className="size-12 text-dark d-block mb-1 text-center">Jumlah Tiket</span>
                                                {
                                                  productTicket[Key].kuota_ticket > 0 ? (
                                                    <div className="group">
                                                      <div className="itemCountr_manual horizontal hz-sm">
                                                        <button className="btn btn_counter co_down" onClick={() => qtyHandler(Key, 'down')}>
                                                          <i className="tio-remove" />
                                                        </button>
                                                        <span className="form-control input_no color-secondary mb-1">
                                                          {dataOrder.length === 0 ? 1 : dataOrder[Key][0].quantity}
                                                        </span>
                                                        <button className="btn btn_counter co_up" onClick={() => qtyHandler(Key, 'up')}>
                                                          <i className="tio-add" />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="group">
                                                      <div className="itemCountr_manual horizontal hz-sm">
                                                        <button className="btn btn_counter co_down" disabled>
                                                          <i className="tio-remove" />
                                                        </button>
                                                        <span className="form-control input_no color-secondary mb-1">
                                                          {dataOrder.length === 0 ? 1 : dataOrder[Key][0].quantity}
                                                        </span>
                                                        <button className="btn btn_counter co_up" disabled>
                                                          <i className="tio-add" />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-100 border-top mt-2 pt-2">
                                            <div className="d-flex align-items-center">
                                              <div className="d-flex flex-column">
                                                <span className="size-12 color-secondary">Total</span>
                                                <span className="size-16 weight-600 color-primary">
                                                  <span className="color-text">Rp </span> 
                                                  {
                                                    priceSplitter(dataOrder[Key][0].priceAfterDisc*dataOrder[Key][0].quantity)
                                                  }
                                                </span>
                                                <span className="mb-1 color-text size-10 mt-0">
                                                  <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={12} alt="" className="mr-1"/>
                                                  Poin
                                                  <span className="ml-1 text-primary size-10 d-inline">{dataOrder[Key][0].poinReward*dataOrder[Key][0].quantity}</span>
                                                </span>
                                              </div>
                                              <div className="ml-auto">
                                                {
                                                  productTicket[Key].kuota_ticket > 0 ? (
                                                    <button className="btn btn-md btn__icon bg-primary color-white text-center" onClick={() => orderHandle(Key)}>
                                                      Pesan
                                                    </button>
                                                  ) : (
                                                    <button className="btn btn__icon bg-primary color-white text-center" disabled>
                                                      Pesan
                                                    </button>
                                                  )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <span className="size-12 color-microsoft mt-2">
                                              {dataOrder.length === 0 ? 1 : dataOrder[Key][0].alertZero}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                }
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-peserta" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <div className="emPage___profile accordion accordion_inset py-0 padding-20 bg-snow pb-2 pt-3" id="accordionExample-inset">
                        <div className="emBody__navLink">
                          <ul>
                            {
                              productTicket.map((ticket, Key) => {
                                return productTicket[Key].ticket_type === 'Peserta' ? (
                                  <li className="item margin-b-10" key={Key}>
                                    <div id="headingOne-inset" className="">
                                      <div className="nav-link main_item d-flex flex-column pb-2" style={{borderRadius : 0}}>
                                        <div className="w-100 d-flex">
                                          <div className="media align-items-center">
                                            <div className="media-body my-auto">
                                              <div className="txt">
                                                <h3 className="mb-0 align-middle">
                                                  <span className="d-inline-block p-1 size-12 weight-600">
                                                    {productTicket[Key].ticket_name}
                                                  </span>
                                                  {
                                                    productTicket[Key].discount === 'Ya' 
                                                    && moment().isAfter(dataOrder[Key][0].discStart)
                                                    && moment().isBefore(dataOrder[Key][0].discEnd)
                                                    ? productTicket[Key].discount_type === 'procentage' ? (
                                                      <span className="badge badge-primary">
                                                        Diskon {productTicket[Key].discount_procentage}%
                                                      </span>
                                                    ) : (
                                                      <span className="badge badge-primary">
                                                        Diskon Rp {priceSplitter(productTicket[Key].discount_rp)}
                                                      </span>
                                                    ) : ''
                                                  }
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="side_right ml-auto color-primary">
                                            <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                              <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id={'collapseOne-inset-'+Key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                                      <div className="card-body bg-white pt-0">
                                        <div className="w-100 border-top pt-2">
                                          <div className="w-auto mb-2">
                                            <span className="size-12 text-dark d-block ">Tanggal Event</span>
                                            {productData.start_date === productData.end_date ? productData.start_date : productData.start_date+' - '+productData.end_date}
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                              <span className="size-12 text-dark">Biaya Pendaftaran</span>
                                              {
                                                productTicket[Key].discount === 'Ya'
                                                && moment().isAfter(dataOrder[Key][0].discStart)
                                                && moment().isBefore(dataOrder[Key][0].discEnd)
                                                ? 
                                                  (
                                                    <span className="size-12 color-text" style={{textDecoration : 'line-through'}}>
                                                      <span className="color-text">Rp</span> {priceSplitter(productTicket[Key].harga_tiket)}
                                                    </span>
                                                  ) : ''
                                              }
                                              <span className="size-14 weight-600 color-secondary">
                                                <span className="color-text">Rp</span> {priceSplitter(dataOrder[Key][0].priceAfterDisc)}
                                              </span>
                                              {
                                                productTicket[Key].kuota_ticket >= 10 ? (
                                                  <span className="size-12 color-medium">Kuota tersedia</span>
                                                ) : productTicket[Key].kuota_ticket > 0 && productTicket[Key].kuota_ticket < 10 ? (
                                                  <span className="size-12 color-yellow">Kuota tersedia dibawah 10</span>
                                                ) : (
                                                  <span className="size-12 color-microsoft">Kuota pendaftaran penuh</span>
                                                )
                                              }
                                            </div>
                                            <div className="d-flex flex-column justify-content-center ml-auto">
                                              {
                                                productTicket[Key].kuota_ticket > 0 ? (
                                                  <button className="btn btn-md btn__icon bg-primary color-white text-center" onClick={() => registerHandle(Key)}>
                                                    Daftar
                                                  </button>
                                                ) : (
                                                  <button className="btn btn__icon bg-primary color-white text-center" disabled>
                                                    Daftar
                                                  </button>
                                                )
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <span className="size-12 color-microsoft mt-2">
                                            {dataOrder.length === 0 ? 1 : dataOrder[Key][0].alertZero}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ) : ('')
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <div className="emPage___profile accordion accordion_inset py-0 padding-20 bg-snow pb-2" id="accordionExample-inset">
                <div className="emBody__navLink">
                  <ul>
                    {
                      productTicket.map((ticket, Key) => {
                        return (
                          <li className="item margin-b-10" key={Key}>
                            <div id="headingOne-inset" className="">
                              {/* <div className="nav-link main_item d-flex flex-column" style={{borderRadius : 0}} data-toggle="collapse" data-target={'#collapseOne-inset-'+Key} aria-expanded="false" aria-controls="collapseOne-inset"> */}
                              <div className="nav-link main_item d-flex flex-column pb-2" style={{borderRadius : 0}}>
                                <div className="w-100 d-flex">
                                  <div className="media align-items-center">
                                    <div className="media-body my-auto">
                                      <div className="txt">
                                        <h3 className="mb-0 align-middle">
                                          <span className="d-inline-block p-1 size-12 weight-600">
                                            {productTicket[Key].ticket_name}
                                          </span>
                                          {
                                            productTicket[Key].discount === 'Ya' 
                                            && moment().isAfter(dataOrder[Key][0].discStart)
                                            && moment().isBefore(dataOrder[Key][0].discEnd)
                                            ? productTicket[Key].discount_type === 'procentage' ? (
                                              <span className="badge badge-primary">
                                                Diskon {productTicket[Key].discount_procentage}%
                                              </span>
                                            ) : (
                                              <span className="badge badge-primary">
                                                Diskon Rp {priceSplitter(productTicket[Key].discount_rp)}
                                              </span>
                                            ) : ''
                                          }
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="side_right ml-auto color-primary">
                                    {/* <i className="tio-chevron_right -arrwo"></i> */}
                                    <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                                      <path fillRule="evenodd" d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id={'collapseOne-inset-'+Key} className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                              <div className="card-body bg-white pt-0">
                                <div className="w-100 border-top pt-2">
                                  <div className="w-auto mb-1">
                                    <span className="size-12 text-dark d-block mb-1">Pilih Tanggal </span>
                                    {
                                      productCategory.current == 1 ? ((
                                        <div className="my-2">
                                          <input type="date" className="form-control form-control-sm" min={dateToday.current} value={dataOrder[Key][0].selectDate === null ? dateToday.current : dataOrder[Key][0].selectDate} onChange={(e) => selectDateChange(Key, e)}/>
                                        </div>
                                      )) : (
                                        dateList.map((date, dateKey) => {
                                          return (
                                            <button 
                                              className={
                                                dataOrder[Key][0].selectDate === null ? 
                                                'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                                (
                                                  dataOrder[Key][0].selectDate === date ? 
                                                  'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                                  'btn btn-outline-primary btn-sm rounded-10 size-12 mb-2 mr-1' 
                                                )
                                              }
                                              style={{padding : '2px 5px 2px 5px'}} 
                                              onClick={() => selectDateHandler(Key, date)}
                                            >
                                              {dateList[dateKey]}
                                            </button>
                                          )
                                        })
                                      )
                                    }
                                    {
                                      // dateList.map((date, dateKey) => {
                                      //   return (
                                      //     <button 
                                      //       className={
                                      //         dataOrder[Key][0].selectDate === null ? 
                                      //         'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                      //         (
                                      //           dataOrder[Key][0].selectDate === date ? 
                                      //           'btn btn-primary btn-sm rounded-10 size-12 mb-2 mr-1' : 
                                      //           'btn btn-outline-primary btn-sm rounded-10 size-12 mb-2 mr-1' 
                                      //         )
                                      //       }
                                      //       style={{padding : '2px 5px 2px 5px'}} 
                                      //       onClick={() => selectDateHandler(Key, date)}
                                      //     >
                                      //       {dateList[dateKey]}
                                      //     </button>
                                      //   )
                                      // })
                                    }
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span className="size-12">Harga /pax</span>
                                      {
                                        productTicket[Key].discount === 'Ya'
                                        && moment().isAfter(dataOrder[Key][0].discStart)
                                        && moment().isBefore(dataOrder[Key][0].discEnd)
                                        ? 
                                          (
                                            <span className="size-12 color-text" style={{textDecoration : 'line-through'}}>
                                              <span className="color-text">Rp</span> {priceSplitter(productTicket[Key].ticket_price_bayar)}
                                            </span>
                                          ) : ''
                                      }
                                      <span className="size-14 weight-600 color-secondary">
                                        <span className="color-text">Rp</span> {priceSplitter(dataOrder[Key][0].priceAfterDisc)}
                                      </span>
                                      {
                                        productTicket[Key].kuota_ticket >= 10 ? (
                                          <span className="size-12 color-medium">Tiket tersedia</span>
                                        ) : productTicket[Key].kuota_ticket > 0 && productTicket[Key].kuota_ticket < 10 ? (
                                          <span className="size-12 color-yellow">Kuota tiket tersedia dibawah 10</span>
                                        ) : (
                                          <span className="size-12 color-microsoft">Tiket tidak tersedia</span>
                                        )
                                      }
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-auto">
                                      <span className="size-12 text-dark d-block mb-1 text-center">Jumlah Tiket</span>
                                      {
                                        productTicket[Key].kuota_ticket > 0 ? (
                                          <div className="group">
                                            <div className="itemCountr_manual horizontal hz-sm">
                                              <button className="btn btn_counter co_down" onClick={() => qtyHandler(Key, 'down')}>
                                                <i className="tio-remove" />
                                              </button>
                                              <span className="form-control input_no color-secondary mb-1">
                                                {dataOrder.length === 0 ? 1 : dataOrder[Key][0].quantity}
                                              </span>
                                              <button className="btn btn_counter co_up" onClick={() => qtyHandler(Key, 'up')}>
                                                <i className="tio-add" />
                                              </button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="group">
                                            <div className="itemCountr_manual horizontal hz-sm">
                                              <button className="btn btn_counter co_down" disabled>
                                                <i className="tio-remove" />
                                              </button>
                                              <span className="form-control input_no color-secondary mb-1">
                                                {dataOrder.length === 0 ? 1 : dataOrder[Key][0].quantity}
                                              </span>
                                              <button className="btn btn_counter co_up" disabled>
                                                <i className="tio-add" />
                                              </button>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="w-100 border-top mt-2 pt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span className="size-12 color-secondary">Total</span>
                                      <span className="size-16 weight-600 color-primary">
                                        <span className="color-text">Rp </span> 
                                        {
                                          priceSplitter(dataOrder[Key][0].priceAfterDisc*dataOrder[Key][0].quantity)
                                        }
                                      </span>
                                      <span className="mb-1 color-text size-10 mt-0">
                                        <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={12} alt="" className="mr-1"/>
                                        Poin
                                        <span className="ml-1 text-primary size-10 d-inline">{dataOrder[Key][0].poinReward*dataOrder[Key][0].quantity}</span>
                                      </span>
                                    </div>
                                    <div className="ml-auto">
                                      {
                                        productTicket[Key].kuota_ticket > 0 ? (
                                          <button className="btn btn-md btn__icon bg-primary color-white text-center" onClick={() => orderHandle(Key)}>
                                            Pesan
                                          </button>
                                        ) : (
                                          <button className="btn btn__icon bg-primary color-white text-center" disabled>
                                            Pesan
                                          </button>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span className="size-12 color-microsoft mt-2">
                                    {dataOrder.length === 0 ? 1 : dataOrder[Key][0].alertZero}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            )
          }

          <div className="padding-t-60 bg-white">
            <div className="em_footerinner border-top">
              <div className="emfo_button __withIcon env-pb d-flex align-items-center justify-content-between">
                <div className="">
                  <span className="d-block size-10 color-comment">Harga Mulai</span>
                  <span className="size-18 weight-600 color-primary">
                    <span className="color-text">Rp</span> {priceSplitter(productData.start_price)}
                  </span>
                </div>
                <button className="btn btn-sm bg-primary color-white text-center justify-content-center px-4" onClick={goToTicket}>
                  Pilih Tiket
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdllButtons_share" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content rounded-15">
            <div className="modal-body rounded-15 p-0">
              <div className="emBK__buttonsShare icon__share padding-20">
                <button type="button" className="btn" data-sharer="facebook" data-hashtag="hashtag" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-facebook rounded-10">
                    <i className="tio-facebook_square" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="telegram" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/" data-to="+44555-5555">
                  <div className="icon bg-telegram rounded-10">
                    <i className="tio-telegram" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="skype" data-url="https://orinostudio.com/nepro/" data-title="Checkout Nepro!">
                  <div className="icon bg-skype rounded-10">
                    <i className="tio-instagram" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="twitter" data-title="Checkout Nepro!" data-hashtags="pwa, Nepro, template, mobile, app, shopping, ecommerce" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-twitter rounded-10">
                    <i className="tio-twitter" />
                  </div>
                </button>
                <button type="button" className="btn" data-sharer="whatsapp" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/">
                  <div className="icon bg-whatsapp rounded-10">
                    <i className="tio-whatsapp_outlined" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail;