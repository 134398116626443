import axios from "axios"

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 
              'Authorization': `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "content-type",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
            }
}

const configNoAuth = {
  headers: { 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "false",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const login = async (formData) => {
  try{
    const reqLogin = await axios.post(`${baseUrl}/login`, formData, configNoAuth)
    if(reqLogin.data.responseCode === 200000){
      localStorage.setItem('user-id', reqLogin.data.responseData.data.id_usercustomer)
      localStorage.setItem('token', reqLogin.data.responseData.token)
      localStorage.setItem('fullname', reqLogin.data.responseData.data.fullname)
      localStorage.setItem('email', reqLogin.data.responseData.data.email)
      localStorage.setItem('profile_picture', reqLogin.data.responseData.data.profile_picture)
      localStorage.setItem('phone_number', reqLogin.data.responseData.data.phone_number)
    }
  
    return reqLogin.data
  }catch(err){
    return err.response.data
  }
}

export const personalData = async (token) => {
  const bodyParameters = {
    token: token
  }

  try{
    const req = await axios.post(`${baseUrl}/personal-details`, bodyParameters, config)
    return req.data
  }catch(err){
    return err.response.data
  }
}

export const updateProfile = async (formData) => {
  try{
    const reqUpdate = await axios.post(`${baseUrl}/update-user`, formData, config)
    return reqUpdate.data
  }catch(err){
    return err.response.data
  }
}

export const updatePassword = async (formData) => {
  try{
    const reqUpdate = await axios.post(`${baseUrl}/update-password`, formData, config)
    return reqUpdate.data
  }catch(err){
    return err.response.data
  }
}

export const logout = async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }
  }

  const bodyParameters = {
    key: "value"
  }
  
  try{
    const reqLogout = await axios.post(`${baseUrl}/logout`, bodyParameters, config)
    localStorage.clear()
    return true
  }catch(err){
    return false
  }
}

export const register = async (formData) => {
  try{
    const reqRegis = await axios.post(`${baseUrl}/register`, formData, configNoAuth)
    return reqRegis.data
  }catch(err){
    return err.response.data
  }
}

export const verification = async (formData) => {
  try{
    const reqRegis = await axios.post(`${baseUrl}/verify`, formData, configNoAuth)
    return reqRegis.data
  }catch(err){
    return err.response.data
  }
}

export const forgotPassword = async (formData) => {
  try{
    const reqForgot = await axios.post(`${baseUrl}/request-password-reset`, formData, configNoAuth)
    return reqForgot.data
  }catch(err){
    return err.response.data
  }
}

export const verifyCodeResetPassword = async (formData) => {
  try{
    const reqForgot = await axios.post(`${baseUrl}/verify-link-password-reset`, formData, configNoAuth)
    return reqForgot.data
  }catch(err){
    return err.response.data
  }
}

export const updatePasswordReset = async (formData) => {
  try{
    const reqForgot = await axios.post(`${baseUrl}/password-reset`, formData, configNoAuth)
    return reqForgot.data
  }catch(err){
    return err.response.data
  }
}

export const thirdPartyAuth = async (googleRes) => {
  const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo",{
                              headers: {
                                Authorization: `Bearer ${googleRes.access_token}`,
                              },
                            }
  )

  const formData = new FormData()
  formData.append('third_party', 'google')
  formData.append('id_third_party', res.data.sub)
  formData.append('email', res.data.email)
  formData.append('fullname', res.data.name)
  formData.append('profile_picture', res.data.picture)

  try{
    const reqAuth = await axios.post(`${baseUrl}/third-party-authentication`, formData, configNoAuth)
    if(reqAuth.data.response === 'SUCCESS'){
      localStorage.setItem('user-id', reqAuth.data.data.id_usercustomer)
      localStorage.setItem('token', reqAuth.data.token)
      localStorage.setItem('fullname', reqAuth.data.data.fullname)
      localStorage.setItem('email', reqAuth.data.data.email)
      localStorage.setItem('profile_picture', reqAuth.data.data.profile_picture)
      localStorage.setItem('phone_number', reqAuth.data.data.phone_number)
    }
    
    return reqAuth.data
  }catch(err){
    return err.response.data
  }
}

export const getPoin = async () => {
  try{
    const req = await axios.get(`${baseUrl}/get-poin`, config)
    return req
  }catch(err){
    return err.response.data
  }
}

export const getPoinUser = async () => {
  try{
    const req = await axios.get(`${baseUrl}/get-poin-user`, config)
    return req.data
  }catch(err){
    return err.response.data
  }
}