import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import moment from 'moment'
import {getVaList, getEwalletList, payment, checkPromo} from '../../Api/Transaction';
import {registration, registrationPersonil} from '../../Api/RegistrationSport';

import {productTicket} from '../../Api/Product'
import {priceSplitter} from '../../Tools/Tools'

function RegisterSport(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState([])
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const qtyTicket = useRef(location.state.qtyTicket)
  const idEvent = useRef(location.state.idEvent)
  const slug = useRef(location.state.slug)
  const idTicket = useRef(location.state.idTicket)
  const selectionDate = useRef(location.state.selectionDate)
  const idUser = localStorage.getItem('user-id')
  const userFullname = localStorage.getItem('fullname') ===  null ? '' : localStorage.getItem('fullname')
  const userPhone = localStorage.getItem('phone_number') ===  null ? '' : (localStorage.getItem('phone_number') ===  'null' ? '' : localStorage.getItem('phone_number'))
  const userEmail = localStorage.getItem('email') ===  null ? '' : localStorage.getItem('email')
  const userPoin = useRef(0)
  const poinUsed = useRef(0)

  const [visitorDiff, setVisitorDiff] = useState(false)
  const [visName, setVisName] = useState(null)
  const [visPhone, setVisPhone] = useState()
  const [visEmail, setVisEmail] = useState()
  const itemDivVisitor = useRef(null)
  const alertDivVisitor = useRef(null)

  const btnPay = useRef(null)
  const btnPayLoading = useRef(null)

  const [methodSelected, setMethodSelected] = useState('')
  const methodPaymentNull = useRef(null)

  const [vaList, setVaList] = useState([])
  const [vaActive, setVaActive] = useState(null)
  const [ewalletList, setEwalletList] = useState([])
  const [ewalletActive, setEwalletActive] = useState([])
  const [methodDisabled, setMethodDisabled] = useState(false)
  const [bankCodeSelected, setBankCodeSelected] = useState()
  const [bankNameSelected, setBankNameSelected] = useState('')
  const [productData, setProductData] = useState([])

  const [insuranceInclude, setInsurancenInclude] = useState(false)

  const [promoCode, setPromoCode] = useState(null)
  const btnPromo = useRef(null)
  const btnPromoLoading = useRef(null)
  const promoApplied = useRef(null)
  const [usePromo, setUsePromo] = useState(false)
  const promoUsedType = useRef('')
  const promoUsedTypeAmount = useRef(0)
  const promoUsedAmount = useRef(0)
  const [promoDisabled, setPromoDisabled] = useState(false)
  const alertPromoCodeFail = useRef(null)
  const alertPromoCodeSuccess = useRef(null)

  const [usePoin, setUsePoin] = useState(false)

  const priceTicket = useRef(0)
  const disc = useRef(0)
  const priceAfterDisc = useRef(0)
  const amount = useRef(0)
  const insurancePrice = useRef(0)
  const taxPrice = useRef(0)
  const final_amount = useRef(0)
  const poin_reward = useRef(0)

  const ticketUnavailableRef = useRef(null)

  const btnReg = useRef(null)
  const btnRegLoading = useRef(null)
  const alertFormTeam = useRef(null)
  const alertFormPersonil = useRef(null)

  const [nameTeam, setNameTeam] = useState('')
  const [instansiTeam, setInstansiTeam] = useState('')
  const personilActive = useRef(0)
  const [personilTeam, setPersonilTeam] = useState([{
                                                      'fullName' : '',
                                                      'gender' : 'M',
                                                      'dob' : '',
                                                      'cardId' : '',
                                                      'otherFile' : '',
                                                    }])
  const [personilView, setPersonilView] = useState([])

  const reqDetail = async () => {
    productTicket(idTicket.current).then((res) => {
      if(res.status == 200){
        setProductData(res.data[0])
        setImageUrl(res.data[0].product_name === 1 ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (res.data[0].product_name === 2 ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
        viewPersonil()
        setLoaderDisplay('none')
      }
    })
  }
  
  const toProductDetail = (slug) => {
    navigate('/product-detail/'+slug)
  }

  const viewPersonil = () => {
    setPersonilView(
      <form action>
        <div className="form-group with_icon">
          <label>Nama Lengkap</label>
          <div className="input_group">
            <input type="text" name="fullName" className="form-control" placeholder="John Smith" value={personilTeam[personilActive.current].fullName} onChange={changeDataPersonil} required />
            <div className="icon">
              <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeWidth={2} d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="form-group with_icon">
          <label>Jenis Kelamin</label>
          <div className="input_group">
            <select name="gender" className="form-control custom-select" value={personilTeam[personilActive.current].gender} onChange={changeDataPersonil}>
              <option value="M">Laki - Laki</option>
              <option value="F">Perempuan</option>
            </select>
            <div className="icon">
              <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth={2} d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="form-group with_icon">
          <label>Tanggal Lahir</label>
          <div className="input_group">
            <input type="date" name="dob" className="form-control" placeholder="example@mail.com" value={personilTeam[personilActive.current].dob} onChange={changeDataPersonil} required />
            <div className="icon">
              <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="form-group with_icon mb-2">
          <label>Kartu Identitas Diri</label>
          <div className="input-group">
            <div className="custom-file">
              <input type="file" name="cardId" onChange={changeDataPersonil} className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" />
              <label className="custom-file-label" htmlFor="inputGroupFile01">{personilTeam[personilActive.current].cardId === '' ? 'Choose file' : personilTeam[personilActive.current].cardId.name}</label>
            </div>
          </div>
          <span className="color-dark size-10">*KTP, Kartu Pelajar, Akta Kelahiran, dll</span>
        </div>
        <div ref={alertFormPersonil} className="d-none">
          <span className="d-block color-red mt-2 w-100 text-center">Data peserta belum lengkap, pastikan data sudah sesuai.</span>
        </div>
      </form>
    )
  }

  const changePersonil = (key) => {
    personilActive.current = key
    viewPersonil()
  }

  const addPersonil = () => {
    var arr_personil = {
      'fullName' : '',
      'gender' : 'M',
      'dob' : '',
      'cardId' : '',
      'otherFile' : '',
    }

    var lengthPersonilTeam = personilTeam.length
    var prev_personil = personilTeam
    prev_personil.push(arr_personil)
    setPersonilTeam([...prev_personil])
    changePersonil(lengthPersonilTeam)
  }

  const changeDataPersonil = (event) => {
    if(event.target.name === 'cardId'){
      personilTeam[personilActive.current][event.target.name] = (event.target.files[0])
      console.log(personilTeam[personilActive.current].cardId.name)
    }else{
      personilTeam[personilActive.current][event.target.name] = (event.target.value)
    }
    
    viewPersonil()
  }

  const checkFormRegister = () => {
    if(nameTeam !== '' || instansiTeam !== ''){
      var form_passed = 1
      for (var key = 0; key < personilTeam.length; key++) {
        if(personilTeam[key].fullName === '' || 
          personilTeam[key].gender === '' ||
          personilTeam[key].dob === '' ||
          personilTeam[key].cardId === ''){
            changePersonil(key)
            alertFormPersonil.current.className = 'd-flex'
            form_passed = 0
            break;
        }
      }
      
      if(form_passed === 1){
        return 1
      }else{
        return 0
      }
    }else{
      alertFormTeam.current.className = 'd-flex'
      return 0
    }
  }

  const registerHandler = () => {
    // console.log(personilTeam)
    alertFormTeam.current.className = 'd-none'
    alertFormPersonil.current.className = 'd-none'
    
    btnReg.current.className = 'd-none'
    btnRegLoading.current.className = 'd-block'

    if(checkFormRegister() === 1){
      const formDataTeam = new FormData()
      formDataTeam.append('id_product', idEvent.current)
      formDataTeam.append('id_ticket', idTicket.current)
      formDataTeam.append('id_customer', idUser)
      formDataTeam.append('registration_type', 'Team')
      formDataTeam.append('name_team', nameTeam)
      formDataTeam.append('instansi', instansiTeam)
      registration(formDataTeam).then((res) => {
        if(res.status == 200){
          var regisPersonilProcess = 0
          var regisPersonilSuccess = 0
          personilTeam.map((personil, key) => {
            const formDataPersonil = new FormData()
            var idRegistration = res.data.id_registration
            formDataPersonil.append('id_registration', idRegistration)
            formDataPersonil.append('fullname', personilTeam[key].fullName)
            formDataPersonil.append('gender', personilTeam[key].gender)
            formDataPersonil.append('dob', personilTeam[key].dob)
            formDataPersonil.append('card_id', personilTeam[key].cardId)
            registrationPersonil(formDataPersonil).then((res) => {
              regisPersonilProcess++
              if(personilTeam.length === regisPersonilProcess){
                btnReg.current.className = 'd-block'
                btnRegLoading.current.className = 'd-none'
              }
  
              if(res.data.status === 'Success'){
                regisPersonilSuccess++
                if(personilTeam.length === regisPersonilSuccess){
                  navigate('/register-sport-checkout', { state: {
                                                    qtyTicket : 1, 
                                                    idEvent : idEvent.current, 
                                                    idTicket : idTicket.current, 
                                                    idRegistration : idRegistration, 
                                                    slug : slug.current, 
                                                    selectionDate : '01-06-2024'
                                                  } })
                }
              }
            })
          })
        }
      })
    }else{
      btnReg.current.className = 'd-block'
      btnRegLoading.current.className = 'd-none'
    }
  }

  useEffect(() => {
    priceTicket.current = productData.ticket_price_bayar
    var start_date_discount = productData.start_date_discount === '' ? '1970-01-01' : productData.start_date_discount
    var start_end_discount = productData.end_date_discount === '' ? '1970-01-01' : productData.end_date_discount
    var discStart = new Date(start_date_discount);
    var discEnd = new Date(start_end_discount);
    if(
      productData.discount === 'Ya' 
      && moment().isAfter(discStart)
      && moment().isBefore(discEnd)
    ){
      if(productData.discount_type === 'procentage'){
        disc.current = (productData.discount_procentage/100)*productData.ticket_price_bayar
      }else{
        disc.current = productData.discount_rp
      }
    }

    priceAfterDisc.current = priceTicket.current-disc.current
    amount.current = priceAfterDisc.current*qtyTicket.current

    if(productData.biaya_pajak_dikenakan_ke == 'Cust'){
      taxPrice.current = productData.biaya_pajak/100*amount.current
    }

    final_amount.current = amount.current+taxPrice.current
    poin_reward.current = productData.poin_reward*qtyTicket.current
  }, [productData])

  useEffect(() => {
    reqDetail()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item shadow-sm">
          <div className="em_side_right">
            <span className="rounded-circle d-flex align-items-center cursor-pointer" onClick={() => toProductDetail(slug.current)}>
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </span>
          </div>
          <div className="title_page">
            <div className="page_name_checkout">Form Registrasi
            </div>
          </div>
        </header>
        <section className="emPage__basket npPkg__basket default" style={{paddingBottom : '60px'}}>
          <div className="emPage___profile accordion accordion_inset" id="accordionExample-inset">
            <div className="emBody__navLink">
              <ul>
                <li className="item margin-b-5">
                  <div id="headingOne-inset">
                    <div className="nav-link main_item" data-toggle="collapse" data-target="#collapseOne-inset" aria-expanded="true" aria-controls="collapseOne-inset">
                      <div className="media align-items-center">
                        <div className="ico">
                          <img className="imgList w-60 h-51 rounded-4" style={{'object-fit' : 'cover', 'cursor' : 'default'}} src={imageUrl+productData.id_event+'/'+productData.foto_banner} alt="" />
                        </div>
                        <div className="media-body my-auto">
                          <div className="txt" style={{'cursor' : 'default'}}>
                            <h3 className="mb-0">{productData.event_name}</h3>
                            <span className="size-14 color-primary">{productData.ticket_name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="side_right">
                        <i className="tio-chevron_right -arrwo"></i>
                      </div>
                    </div>
                  </div>
                  <div id="collapseOne-inset" className="collapse show" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                    <div className="card-body pt-0 pb-2">
                      <div className="pt-2 border-top">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                          </svg>
                          {productData.start_date === productData.end_date ? productData.start_date : productData.start_date+' - '+productData.end_date}
                        </span>
                      </div>
                      <div className="mt-1">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                          </svg>
                          {productData.location_name+', '+productData.address+', '+productData.kota+', '+productData.province}
                        </span>
                      </div>
                      <div className="mt-2 border-top pt-2">
                        <span className="size-14 mb-1">ketentuan Pendaftaran</span>
                        <div className="color-text ml-4">
                          <div dangerouslySetInnerHTML={{__html: productData.info_penting}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Informasi Umum</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="bg-white padding-10">
                  <form action>
                    <div className="form-group inputDefault-animated mb-3">
                      <div className="group_anime">
                        <input type="text" className="form-control" placeholder="John Smith" value={nameTeam === null ? '' : nameTeam} onChange={(e) => setNameTeam(e.target.value)} required />
                        <label>Nama Tim</label>
                      </div>
                    </div>
                    <div className="form-group inputDefault-animated mb-0">
                      <div className="group_anime">
                        <input type="text" className="form-control" placeholder="example@mail.com" value={instansiTeam === null ? '' : instansiTeam} onChange={(e) => setInstansiTeam(e.target.value)} required />
                        <label>Asal Sekolah / Instansi</label>
                      </div>
                    </div>
                    <div ref={alertFormTeam} className="d-none">
                      <span className="d-block color-red mt-2 w-100 text-center">Data tim yang kamu input belum lengkap.</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Anggota Tim</h2>
            </div>
            <div className="emTitle_co padding-r-20 padding-l-20 mb-2 d-flex justify-content-between align-items-center">
              <div className="dropdown default mb-0 w-50">
                <button className="btn btn-outline-secondary btn-block dropdown-toggle d-flex justify-content-between" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Pemain {personilActive.current+1}
                </button>
                <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                  {
                    personilTeam.map((personil, personKey) => {
                                                                return (
                                                                  <span key={personKey} className="dropdown-item" onClick={event => {changePersonil(personKey)}}>Pemain {personKey+1}</span>
                                                                )
                                                              }) 
                  }
                </div>
              </div>
              <div className='form-group mb-0'>
                <button className="btn btn-primary" onClick={addPersonil}>Tambah Pemain</button>
              </div>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="bg-white padding-10">
                  {personilView}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-snow padding-b-20">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Biaya</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14" data-toggle="modal" data-target="#mdl_detail_insurance">
                      Pendaftaran
                    </span>
                  </div>
                  <div className="em_toggle ml-auto">
                    <span className="size-16 weight-600 color-primary d-block"><span className="color-text">Rp </span> {priceSplitter(final_amount.current)}</span>
                  </div>
                </div>
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14">Tax</span>
                    {
                      productData.biaya_pajak_dikenakan_ke === 'Cust' ? (
                        ''
                      ) : (
                        <span className="size-12 color-text d-block">Biaya ditanggung oleh pihak EO</span>
                      )
                    }
                  </div>
                  <div className="em_toggle ml-auto">
                    <span className="size-14">Rp {priceSplitter(taxPrice.current)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          <div className="env-pb text-center">
            <div ref={btnReg} className="d-block">
              <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" onClick={registerHandler}>
                <b>Registrasi</b>
              </button>
            </div>
            <div ref={btnRegLoading} className="d-none">
              <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" disabled>
                <div className="icon rounded-circle">
                  <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterSport;