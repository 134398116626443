import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom"

function Promo(){
  const navigate = useNavigate()
  const location = useLocation()
  const imageUrl = process.env.REACT_APP_IMAGE_URL

  useEffect(() => {

  },[])

  return (
    <div id="wrapper" className="vh-100">
      <div id="content">
        <header className="main_haeder header-sticky multi_item shadow-sm">
          <div className="em_menu_sidebar">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href='/home'>
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name text-dark">Promo</span>
          </div>
          <div className="em_side_right">
            {/* <button type="button" className="btn btn_meunSearch" id="saerch-On-header">
              <i className="ri-search-2-line" />
            </button> */}
          </div>
        </header>
        <div className="bg-white pt-4" style={{minHeight : '100vh'}}>
          <div className="emTitle_co bg-white padding-20 padding-b-10 position-sticky" style={{top: '45px', zIndex : 10}}>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Promo;