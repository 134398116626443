import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom"

import Header from '../Components/Header'
import Main from '../Components/Main'
import Footer from '../Components/Footer'
import SidePanel from '../Components/SidePanel'
import SearchBar from '../Components/SearchBar'
import ModalShare from '../Components/ModalShare'

const Homepage = () => {
  const navigate = useNavigate();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`} target='_blank'>{children}</a>
    );
  };

  useEffect(() => {
    // checkUserToken()
  })
  
  return(
    <div>
      <div id="wrapper">
        <div id="content">
          <Header/>
          <Main/>
        </div>
        <Footer/>
        <SearchBar/>
        <SidePanel/>
        <ModalShare/>

        <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_home_menu" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header padding-l-20 padding-r-20 border-bottom">
                <div className="itemProduct_sm">
                  <h1 className="size-16 weight-600 color-secondary m-0">Menu</h1>
                </div>
                <div className="absolute right-0 padding-r-20">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="tio-clear" />
                  </button>
                </div>
              </div>
              <div className="modal-body p-0" style={{minHeight : '10vh'}}>
                <div className="emPage__basket npPkg__basket default py-1">
                  <div className="em__pkLink accordion bg-white" id="accordionExample5">
                    <ul className="nav__list with_border fullBorder mb-4">
                      <li>
                        <div>
                          <a className="item-link main_item" href="https://mitra.juaratiket.com/" target="_blank">
                            <div className="group">
                              <div>
                                <span className="path__name">Mitra Juara Tiket</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </a>
                        </div>
                      </li>
                      {/* <li>
                        <div>
                          <a className="item-link main_item" href="https://mitra.juaratiket.com/" target="_blank">
                            <div className="group">
                              <div>
                                <span className="path__name">Poin Rewards</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </a>
                        </div>
                      </li> */}
                      <li>
                        <div>
                          <a className="item-link main_item" href="/promo">
                            <div className="group">
                              <div>
                                <span className="path__name">Promo & Diskon</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div>
                          <a className="item-link main_item" href="https://mitra.juaratiket.com/" target="_blank">
                            <div className="group">
                              <div>
                                <span className="path__name">Paket Voucher</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div id="heading_insurance">
                          <div className="item-link main_item" data-toggle="collapse" data-target="#collapse_insurance" aria-expanded="false" aria-controls="collapseTwo-text">
                            <div className="group">
                              <div>
                                <span className="path__name">Perlindungan Asuransi</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </div>
                        </div>
                        <div id="collapse_insurance" className="collapse" aria-labelledby="headingTwo-text" data-parent="#accordionExample5">
                          <div className="em__pkLink">
                            <ul className="nav__list with_border fullBorder mb-0 pl-2">
                              <li className="border-0">
                                <a href="#" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Partner Asuransi</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                <a href="#" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Manfaat & Kompensasi</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                <a href="#" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">SOP Klaim</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                <a href="#" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Contact Center</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                <a href="#" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Klaim Asuransi</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div id="heading_help">
                          <div className="item-link main_item" data-toggle="collapse" data-target="#collapse_help" aria-expanded="false" aria-controls="collapseThree-text">
                            <div className="group">
                              <div>
                                <span className="path__name">Pusat Bantuan</span>
                              </div>
                            </div>
                            <div className="group">
                              <span className="short__name" />
                              <i className="tio-chevron_right -arrwo" />
                            </div>
                          </div>
                        </div>
                        <div id="collapse_help" className="collapse" aria-labelledby="headingThree-text" data-parent="#accordionExample5">
                          <div className="em__pkLink">
                            <ul className="nav__list with_border fullBorder mb-0 pl-2">
                              <li className="border-0">
                                <a href="https://wa.me/6281317409518" target="_blank" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Whatsapp</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                {/* <Mailto email="foo@bar.baz" subject="Hello & Welcome" body="Hello world!">
                                  Mail me!
                                </Mailto> */}
                                <a href="mailto:juaratiketplatform@gmail.com" target="_blank" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Email</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                              <li className="border-0">
                                <a href="https://direct.lc.chat/17738955/" target="_blank" className="item-link">
                                  <div className="group">
                                    <span className="size-14 color-text">Live Chat</span>
                                  </div>
                                  <div className="group">
                                    <i className="tio-chevron_right -arrwo" />
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                        <div>
                          <a className="item-link main_item" href="https://mitra.juaratiket.com/" target="_blank">
                            <div className="group">
                              <div>
                                <span className="path__name">Dark Mode</span>
                              </div>
                            </div>
                            <div className="group">
                              <label className="switch_toggle toggle_md ml-auto" htmlFor="switchDarkMode">
                                <input type="checkbox" checked='false' className="switchDarkMode" id="switchDarkMode" />
                                <span className="handle" />
                              </label>
                            </div>
                          </a>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Homepage