import React, { useRef, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { thirdPartyAuth } from '../Api/Auth';

function ButtonGoogleSignIn(){
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const btnGoogle = useRef(null)
  const btnGoogleLogin = useRef(null)
  const [googleName, setGoogleName] = useState("")
  const [googleEmail, setGoogleEmail] = useState("")
  const [googleImage, setGoogleImage] = useState("")
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      thirdPartyAuth(response).then((res) => {
        setLoaderDisplayBg0('flex')
        if(res.response === 'SUCCESS'){
          console.log(res.data)
          setGoogleName(res.data.fullname)
          setGoogleEmail(res.data.email)
          setGoogleImage(res.data.profile_picture)
          btnGoogle.current.className = 'd-none'
          btnGoogleLogin.current.className = 'd-block'
          setTimeout(function() { 
            window.location.replace('/home')
          }, 4000)
        }else{
          console.log(res.response)
        }
      })
    }
  })

  return(
    <div>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div ref={btnGoogle} className="d-block">
        <button id="google" onClick={() => googleLogin()} className="btn __google border-solid border-snow rounded-10" style={{width : 'auto', height : '50px'}}>
          <div className="icon">
            <svg id="search" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 19.469 19.469">
              <path id="Path_1675" data-name="Path 1675" d="M4.315,144.776l-.678,2.53-2.477.052a9.752,9.752,0,0,1-.072-9.09h0l2.205.4.966,2.192a5.81,5.81,0,0,0,.055,3.911Z" transform="translate(0 -133.01)" fill="#fbbb00" />
              <path id="Path_1676" data-name="Path 1676" d="M270.978,208.176a9.731,9.731,0,0,1-3.47,9.41h0l-2.778-.142-.393-2.454a5.8,5.8,0,0,0,2.5-2.963h-5.205v-3.851h9.35Z" transform="translate(-251.679 -200.26)" fill="#518ef8" />
              <path id="Path_1677" data-name="Path 1677" d="M45.177,314.969h0a9.738,9.738,0,0,1-14.669-2.978l3.155-2.582a5.79,5.79,0,0,0,8.343,2.964Z" transform="translate(-29.349 -297.643)" fill="#28b446" />
              <path id="Path_1678" data-name="Path 1678" d="M43.484,2.241,40.33,4.823A5.789,5.789,0,0,0,31.8,7.854l-3.171-2.6h0a9.737,9.737,0,0,1,14.86-3.017Z" transform="translate(-27.536)" fill="#f14336" />
            </svg>
          </div>
        </button>
      </div>
      <div ref={btnGoogleLogin} className="d-none">
        <button onClick={() => googleLogin()} className="btn border-solid border-snow rounded-10 text-left" style={{width : 'auto', height : '50px'}}>
          <img className="imgList w-30 h-30 rounded-circle mr-2" src={googleImage} alt=""/>
          <span className="d-block size-12">
            <b className="d-block">Sign in as {googleName}</b> 
            {googleEmail}
          </span>
          <div className="icon ml-2">
            <svg id="search" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 19.469 19.469">
              <path id="Path_1675" data-name="Path 1675" d="M4.315,144.776l-.678,2.53-2.477.052a9.752,9.752,0,0,1-.072-9.09h0l2.205.4.966,2.192a5.81,5.81,0,0,0,.055,3.911Z" transform="translate(0 -133.01)" fill="#fbbb00" />
              <path id="Path_1676" data-name="Path 1676" d="M270.978,208.176a9.731,9.731,0,0,1-3.47,9.41h0l-2.778-.142-.393-2.454a5.8,5.8,0,0,0,2.5-2.963h-5.205v-3.851h9.35Z" transform="translate(-251.679 -200.26)" fill="#518ef8" />
              <path id="Path_1677" data-name="Path 1677" d="M45.177,314.969h0a9.738,9.738,0,0,1-14.669-2.978l3.155-2.582a5.79,5.79,0,0,0,8.343,2.964Z" transform="translate(-29.349 -297.643)" fill="#28b446" />
              <path id="Path_1678" data-name="Path 1678" d="M43.484,2.241,40.33,4.823A5.789,5.789,0,0,0,31.8,7.854l-3.171-2.6h0a9.737,9.737,0,0,1,14.86-3.017Z" transform="translate(-27.536)" fill="#f14336" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ButtonGoogleSignIn