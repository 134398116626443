import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import { addDays } from 'date-fns'
import { DateRange } from 'react-date-range'
import moment from 'moment'

import {provinceList} from '../../Api/Master'
import {productList, formatEvent, searchProduct} from '../../Api/Product'
import {priceSplitter} from '../../Tools/Tools'

function Product(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState([])

  const [productCategory, setProductCategory] = useState(location.state.productCategory)
  const [initialKeyword, setinitialkeyword] = useState(location.state.keyword)
  const [displayLoadSpinner, setDisplayLoadSpinner] = useState("flex")
  const [formatEventList, setFormatEventList] = useState([])
  const [formatSelected, setFormatSelected] = useState(null)

  const [province, setProvince] = useState([])
  const [provinceView, setProvinceView] = useState([])
  const [provinceSelected, setProvinceSelected] = useState([])

  const [productEvent, setProductEvent] = useState([])
  const [viewEvent, setViewEvent] = useState()
  const [keywordSearch, setKeywordSearch] = useState()
  const [tmpKeywordSearch, setTmpKeywordSearch] = useState()
  const alertDisplaySearch = useRef(null)
  const infoDisplaySearch = useRef(null)
  const infoDisplaySort = useRef(null)

  const [paramSort, setParamSort] = useState()
  const [sortOption, setSortOption] = useState('lastAdded')

  const elementPriceFrom = window.$('#priceRangeFrom')
  const elementPriceTo = window.$('#priceRangeTo')

  const reqFormatEvent = async () => {
    formatEvent(productCategory).then((res) => {
      if(res.status === 200){
        setFormatEventList(res.data)
      }
    })
  }

  const reqProvince = async () => {
    provinceList().then((res) => {
      if(res.status === 200){
        setProvince(res.data)
      }
    })
  }

  useEffect(() => {
    setProvinceView(
      province.map((provinceList, Key) => {
        // provinceSelected === null ? setProvinceSelected(province[Key]['name']) : ''
        if(provinceSelected === ''){
          console.log(province[Key]['name'])
        }
        return (
          <option id={province[Key]['id_province']} value={province[Key]['name']}>{province[Key]['name']}</option>
        )
      })
    )
  },[province])

  const reqProduct = async (format = 'all', sort = 'lastAdded', ds = 1, de = 1, province = 'all', priceForm = 0, priceTo = 0, category = productCategory) => {
    setImageUrl(category === 1 ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (category === 2 ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
    productList(format, sort, ds, de, province, priceForm, priceTo, category).then((res) => {
      if(res.status === 200){
        setDisplayLoadSpinner('none')
        setProductEvent(res.data)
      }
    })
  }

  useEffect(() => {
    setViewEvent(
      productEvent.map((event, Key) => {
        return (
          // <div key={Key} className="left__side" style={{minWidth : '45%', maxWidth : '45%'}}>
          <div key={Key} className={productEvent.length > 1 ? 'col-6' : 'col-10'}>
            <div onClick={() => toProductDetail(productEvent[Key].slug_url)} className="item__card shadow-sm">
              <div className="cover_img">
                <img src={imageUrl+productEvent[Key].id_event+'/'+productEvent[Key].foto_banner} alt="" />
              </div>
              <div className="card__body p-2">
                <div>
                  <span className="mb-1" style={{fontSize:'11px'}}>
                    {productEvent[Key].start_date === productEvent[Key].end_date ? productEvent[Key].start_date : productEvent[Key].start_date+' - '+productEvent[Key].end_date}
                  </span>
                </div>
                <div className="mb-1 h-60 d-flex align-items-start">
                  <h5 className="size-14" style={{'color' : '#8821f6', height : 'auto'}}>
                    {productEvent[Key].event_name}
                  </h5>
                </div>
                <span className="mb-1 color-text size-12">
                {productEvent[Key].kota}
                </span>
                <h5 className="text-danger mb-1 size-14">
                  <span className="size-10 d-inline mr-1">Start</span>Rp {priceSplitter(productEvent[Key].start_price === null ? 0 : productEvent[Key].start_price)}
                </h5>
                <span className="mb-1 color-text size-10 mt-0">
                  <img src="assets/star.png" width={'auto'} height={12} alt="" className="mr-1"/>
                  Poin 
                  <span className="ml-1 text-primary size-10 d-inline">{productEvent[Key].poin_reward}</span>
                </span>
              </div>
            </div>
          </div>
        )
      })
    )
    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  },[productEvent])
  
  const formatHandler = (format, key) => {
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    setParamSort('')
    infoDisplaySort.current.className = 'd-none'
    reqProduct(format)
    setFormatSelected(key)
  }

  const searchHandler = (e) => {
    e.preventDefault()
    setTmpKeywordSearch(keywordSearch)
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    const formData = new FormData()
    formData.append('keyword', keywordSearch)
    searchProduct(formData).then((res) => {
      setDisplayLoadSpinner('none')
      setProductEvent(res.data)
      if(res.data.length === 0){
        alertDisplaySearch.current.className = 'd-block'
        infoDisplaySearch.current.className = 'd-none'
      }else{
        alertDisplaySearch.current.className = 'd-none'
        infoDisplaySearch.current.className = 'd-block'
      }
    })
  }

  const clearSearchHandler = () => {
    setKeywordSearch('')
    setinitialkeyword(null)
    setDisplayLoadSpinner('flex')
    alertDisplaySearch.current.className = 'd-none'
    infoDisplaySearch.current.className = 'd-none'
    reqProduct()
  }

  const onSortChange = (e) => {
    setSortOption(e.target.value)
  }

  const sortHandler = (e) => {
    e.preventDefault()
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    if(sortOption === 'lastAdded'){
      setParamSort('Baru Ditambahkan')
      reqProduct('all', 'lastAdded')
    }else if(sortOption === 'popular'){
      setParamSort('Popular')
      reqProduct('all', 'popular')
    }else if(sortOption === 'highPrice'){
      setParamSort('Harga Tertinggi')
      reqProduct('all', 'highPrice')
    }else if(sortOption === 'lowPrice'){
      setParamSort('Harga Terendah')
      reqProduct('all', 'lowPrice')
    }

    infoDisplaySort.current.className = 'd-block'
    window.$('#mdll_content_sort').modal('hide');
  }

  const [filterDateRange, setFilterDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 30),
      key: 'selection'
    }
  ])

  const dateRangeHandler = (e) => {
    e.preventDefault()
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    var ds = moment(filterDateRange[0]['startDate']).format('DD-MM-YYYY')
    var de = moment(filterDateRange[0]['endDate']).format('DD-MM-YYYY')
    reqProduct('all', 'lastAdded', ds, de)
    setParamSort(moment(filterDateRange[0]['startDate']).format('DD MMM YYYY')+' - '+moment(filterDateRange[0]['endDate']).format('DD MMM YYYY'))
    infoDisplaySort.current.className = 'd-block'
    window.$('#mdll_date_range').modal('hide');
  }

  const priceRangeHandle = () => {
    setViewEvent(null)
    setDisplayLoadSpinner('flex')

    var from = elementPriceFrom[0].defaultValue
    var to = elementPriceTo[0].defaultValue
    var fromReplace = from.replaceAll('.', '')
    var toReplace = to.replaceAll('.', '')

    reqProduct('all', 'lastAdded', 1, 1, 'all', fromReplace, toReplace)
    setParamSort('Rp '+from+' - '+'Rp '+to)
    infoDisplaySort.current.className = 'd-block'
    window.$('#mdll_price_range').modal('hide');
  }

  const filterHandler = (e) => {
    e.preventDefault()
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    reqProduct('all', 'lastAdded', 1, 1, provinceSelected)
    setParamSort(provinceSelected)
    infoDisplaySort.current.className = 'd-block'
    window.$('#mdll_content_filter').modal('hide');
  }

  const resetFilter = () => {
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    reqProduct('all', 'lastAdded', 1, 1, 'all')
    setParamSort('')
    infoDisplaySort.current.className = 'd-none'
  }

  const toProductDetail = (slug) => {
    navigate('/product-detail/'+slug)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML="$('.js-range-slider').ionRangeSlider({skin: 'round',type: 'double',min: min,max: max,from: 50000,to: 900000,onStart: updateInputs,onChange: updateInputs})"
    document.body.appendChild(script)
    
    reqFormatEvent()
    reqProvince()
    if(initialKeyword !== null){
      setKeywordSearch(initialKeyword)
      setDisplayLoadSpinner('flex')
      const formData = new FormData()
      formData.append('keyword', initialKeyword)
      searchProduct(formData).then((res) => {
        setDisplayLoadSpinner('none')
        setProductEvent(res.data)
        if(res.data.length === 0){
          alertDisplaySearch.current.className = 'd-block'
          infoDisplaySearch.current.className = 'd-none'
        }else{
          alertDisplaySearch.current.className = 'd-none'
          infoDisplaySearch.current.className = 'd-block'
        }
      })
    }else{
      reqProduct()
    }
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <div id="content">
        <header className="main_haeder header-sticky multi_item shadow-sm">
          <div className="em_menu_sidebar">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href='/home'>
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name color-secondary">Events</span>
          </div>
          <div className="em_side_right">
            <button type="button" className="btn btn_meunSearch" id="saerch-On-header">
              <i className="ri-search-2-line" />
            </button>
          </div>
        </header>
        <div className="bg-white pt-4" style={{minHeight : '100vh'}}>
          <div className="emTitle_co bg-white padding-20 padding-b-10 position-sticky" style={{top: '45px', zIndex : 10}}>
            <div className="d-flex align-items-center my-2">
              <div className="d-inline-block h-auto overflow-auto text-nowrap">
                <button 
                  className={
                    formatSelected === null ? 
                    'btn btn-primary btn-sm rounded-10 size-14 mr-1 px-2' : 
                    'btn btn-outline-primary btn-sm rounded-10 size-14 mr-1 px-2' 
                  }
                  style={{padding : '2px 5px 2px 5px'}}
                  onClick={() => {formatHandler('all', null)}}
                >
                  All
                </button>
                {
                  formatEventList.map((format, Key) => {
                    return (
                      <button 
                        key={Key}
                        className={
                          formatSelected === Key ? 
                          'btn btn-primary btn-sm rounded-10 size-14 mr-1 px-2' : 
                          'btn btn-outline-primary btn-sm rounded-10 size-14 mr-1 px-2' 
                        }
                        style={{padding : '2px 5px 2px 5px'}} 
                        onClick={() => {formatHandler(formatEventList[Key].id_product_event_format, Key)}}
                      >
                        {formatEventList[Key].format_name}
                      </button>)
                  })
                }
              </div>
            </div>
            <p className="size-12 color-text m-0">
              Menampilkan 
              <span className="font-weight-bolder d-inline"> {productEvent.length} </span> 
                Data 
              <span ref={infoDisplaySearch} className="d-none">
                untuk kata kunci pencarian <b> "{tmpKeywordSearch}"</b>
              </span>
              <span ref={infoDisplaySort} className="d-none">
                <b> "{paramSort}"</b> | 
                <span className="color-primary cursor-pointer" onClick={(() => resetFilter())}> Hapus Filter</span>
              </span>
            </p>
          </div>
          <div className="w-100 justify-content-center padding-t-20" style={{display : displayLoadSpinner}}>
            <div className="spinner_loading">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          </div>
          <div className="emPage__blog d-flex flex-wrap padding-t-20" style={{minHeight : '100vh'}}>
            <div ref={alertDisplaySearch} className="d-none">
              <p className="color-text size-12 text-left">Tidak ada data ditemukan untuk kata kunci pencarian <b>"{keywordSearch}"</b>.</p>
            </div>
            <div className="row">
              {viewEvent}
            </div>
          </div>
          <div className="em_footerinner bg-white rounded- border-top position-sticky" style={{bottom: '0px'}}>
            <div className="emfo_button __withIcon env-pb d-flex align-items-center justify-content-around">
              <button type="button" className="btn d-flex flex-column align-items-center" data-toggle="modal" data-target="#mdll_content_filter">
                <svg className="w-20 h-20 color-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.2} d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4" />
                </svg>
                <span className="size-12 d-block mt-1 color-comment">Filter</span>
              </button>
              <button type="button" className="btn d-flex flex-column align-items-center" data-toggle="modal" data-target="#mdll_date_range">
                <svg className="w-20 h-20 color-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                </svg>
                <span className="size-12 d-block mt-1 color-comment">Tanggal</span>
              </button>
              <button type="button" className="btn d-flex flex-column align-items-center" data-toggle="modal" data-target="#mdll_price_range">
                <svg className="w-20 h-20 color-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeWidth={2} d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>
                <span className="size-12 d-block mt-1 color-comment">Harga</span>
              </button>
              <button type="button" className="btn d-flex flex-column align-items-center" data-toggle="modal" data-target="#mdll_content_sort">
                <svg className="w-20 h-20 color-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4" />
                </svg>
                <span className="size-12 d-block mt-1 color-comment">Urutkan</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <section className={initialKeyword !== null ? "searchMenu__hdr -show" : "searchMenu__hdr"}>
        <form onSubmit={searchHandler}>
          <div className="form-group">
            <div className="input_group">
              <input 
              type="search" 
              className="form-control" 
              placeholder="Cari tiket disini..." 
              value={keywordSearch} onChange={(e) => setKeywordSearch(e.target.value)} 
              required/>
              <i className="ri-search-2-line icon_serach"/>
            </div>
          </div>
        </form>

        <button type="button" className="btn btn_meunSearch -close __removeMenu" onClick={() => clearSearchHandler()}>
          <i className="tio-clear" />
        </button>
      </section>

      <div className="modal transition-bottom screenFull defaultModal mdlladd__rate fade" id="mdll_content_sort" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 justify-content-start">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Urutkan Berdasarkan</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="bg-white py-2">
                <form onSubmit={sortHandler}>
                  <div className="custom-control custom-radio margin-b-10">
                    <input type="radio" id="customRadio4" name="customRadio" className="custom-control-input" 
                    value="lastAdded"
                    checked={sortOption === "lastAdded"}
                    onChange={onSortChange} />
                    <label className="custom-control-label padding-l-10 color-comment" htmlFor="customRadio4">
                      Baru Ditambahkan
                    </label>
                  </div>
                  <div className="custom-control custom-radio margin-b-10">
                    <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" 
                    value="popular"
                    checked={sortOption === "popular"}
                    onChange={onSortChange} />
                    <label className="custom-control-label padding-l-10 color-comment" htmlFor="customRadio1">
                      Populer
                    </label>
                  </div>
                  <div className="custom-control custom-radio margin-b-10">
                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" 
                    value="highPrice"
                    checked={sortOption === "highPrice"}
                    onChange={onSortChange} />
                    <label className="custom-control-label padding-l-10 color-comment" htmlFor="customRadio2">
                      Harga Tertinggi
                    </label>
                  </div>
                  <div className="custom-control custom-radio margin-b-10">
                    <input type="radio" id="customRadio3" name="customRadio" className="custom-control-input" 
                    value="lowPrice"
                    checked={sortOption === "lowPrice"}
                    onChange={onSortChange} />
                    <label className="custom-control-label padding-l-10 color-comment" htmlFor="customRadio3">
                      Harga Terendah
                    </label>
                  </div>
                  {/* <div className="dividar mb-2"></div> */}
                  <div className="modal-footer pb-2 d-flex justify-content-center">
                    <button className="btn btn-sm bg-secondary w-50 m-0 hover:color-white color-white border-0 m-0 d-flex align-items-center rounded-8 justify-content-center">
                      Terapkan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal transition-bottom screenFull defaultModal mdlladd__rate fade" id="mdll_date_range" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 justify-content-start">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Sesuaikan Jadwalmu</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="bg-white py-2">
                <form onSubmit={dateRangeHandler}>
                  <div className="w-100 d-flex flex-column align-items-center">
                    <DateRange
                      editableDateInputs={true}
                      onChange={item => setFilterDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={filterDateRange}
                      rangeColors={["#5569ff"]}
                    />
                  </div>
                  <div className="w-100 dividar mb-2"></div>
                  <div className="modal-footer pb-2 d-flex justify-content-center">
                    <button className="btn btn-sm bg-secondary w-50 m-0 hover:color-white color-white border-0 m-0 d-flex align-items-center rounded-8 justify-content-center">
                      Terapkan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal transition-bottom screenFull defaultModal mdlladd__rate fade" id="mdll_content_filter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 justify-content-start">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Filter</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="bg-white py-2">
                <form onSubmit={filterHandler}>
                  <div class="form-group py-3">
                    <label>Area</label>
                    <select class="form-control form-control-sm custom-select" onChange={(e) => setProvinceSelected(e.target.value)}>
                      <option value="0" disabled selected required>Pilih Area Event</option>
                      {provinceView}
                    </select>
                  </div>
                  {/* <div className="dividar mb-2"></div> */}
                  <div className="modal-footer pb-2 d-flex justify-content-center">
                    <button className="btn btn-sm bg-secondary w-50 m-0 hover:color-white color-white border-0 m-0 d-flex align-items-center rounded-8 justify-content-center">
                      Terapkan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal transition-bottom screenFull defaultModal emModal__filters fade" id="mdll_price_range" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 justify-content-start">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Tentukan Harga Sesukamu</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body padding-b-100">
              <div className="em_box_content_filter">
                <div className="slider__range_chart">
                  <div className="title_bk margin-t-20">
                    <h2>Harga (Rp)</h2>
                    <div className="extra-controls">
                      <input id="priceRangeFrom" type="text" className="form-control js-input-from" defaultValue={'50.000'}/>
                      <span className="color-text">-</span>
                      <input id="priceRangeTo" type="text" className="form-control js-input-to" defaultValue={'900.000'}/>
                    </div>
                  </div>
                  <div className="range-slider">
                    <input type="text" className="js-range-slider" data-prefix="Rp" defaultValue />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 pb-2 d-flex justify-content-center">
              <button onClick={() => priceRangeHandle()} className="btn btn-sm bg-secondary w-50 m-0 hover:color-white color-white border-0 m-0 d-flex align-items-center rounded-8 justify-content-center">
                Terapkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product;