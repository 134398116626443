import React, {useEffect, useRef, useState} from 'react'
import QRCode from 'react-qr-code';
import {useParams, useLocation} from "react-router-dom"
import {priceSplitter} from '../../Tools/Tools'

function PaymentEwallet(){
  const location = useLocation()
  const expectedAmount = useRef(location.state.expectedAmount)
  const desktopWebUrl = useRef(location.state.desktopWebUrl)
  const mobileWebUrl = useRef(location.state.mobileWebUrl)
  const mobileDeeplinkUrl = useRef(location.state.mobileDeeplinkUrl)
  const channelCode = useRef(location.state.channelCode)
  const eventName = useRef(location.state.event_name)
  const ticketName = useRef(location.state.ticket_name)
  const topikName = useRef(location.state.topik_name)
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  useEffect(() => {
    setTimeout(function(){ 
      setLoaderDisplay('none')
    
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 1000)
  },[])

  return (
    <div id="wrapper" style={{'height' : '100vh'}}>
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" style={{height : '100vh'}}>
        <section className="background_header bg-primary" style={{'height' : 'auto'}}>
          <div className="npPage_SuccessPkg p-5">
            <div className="txt">
              <span className="d-block size-16 color-white font-weight-bolder">Selesaikan Segera Pembayaranmu Melalui</span>
              <div className="logo-payment bg-white my-3 w-50 rounded-10 py-2">
                <img src={'/assets/img/e-wallet/'+channelCode.current.toLowerCase()+'-logo.svg'} alt="" width={'80%'} height={'auto'}/>
              </div>
              <span className="d-block size-16 color-white font-weight-bolder">E-WALLET</span>
            </div>
          </div>
        </section>
        <section className="padding-20">
          <div className="title_sm pb-3">
            <span>Detail Pembayaran</span>
          </div>
          <div className="emPage__basket npPkg__basket justBoxes">
            <div className="itemPkg_cart">
              <div className="sideleft">
                <div>
                  <p>Tiket {ticketName.current}</p>
                  <h3>{eventName.current}</h3>
                </div>
              </div>
              <div className="price">
                <span className="size-13 color-primary">{topikName.current}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="padding-20 pt-0">
          <div className="npPkg__paymentDetails">
            <ul>
              <li>
                <span>Total Pembayaran</span>
                <span className="weight-600"><span className="color-primary">Rp {priceSplitter(expectedAmount.current)}</span></span>
              </li>
            </ul>
          </div>
        </section>
        {
          channelCode.current !== 'OVO' ? (
            <section className="padding-20 pt-0">
              <div className="npPkg__paymentDetails py-2" style={{backgroundColor : '#e8e8e8'}}>
              <span className="d-block size-14 color-comment mb-1">
                <svg className="w-16 h-16 mr-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
                 Notes 
              </span>
              <span className="d-block size-12 color-comment">
                <span className="color-comment">
                  Klik tombol dibawah untuk membuka aplikasi pembayaranmu.
                </span>
              </span>
              </div>
            </section>
          ) : ('')
        }
      </div>
        
      <div className="buttons__footer" style={{bottom : 0}}>
        {
          channelCode.current !== 'OVO' ? (
            <div className="env-pb text-center">
              <a 
                href={mobileWebUrl.current != null ? mobileWebUrl.current : (mobileDeeplinkUrl.current != null ? mobileDeeplinkUrl.current : '#')}
                target="_blank"
                className="btn bg-primary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder"
              >
                Open <b>{channelCode.current}</b> App
              </a>
            </div>
          ) : ('')
        }
        <div className="env-pb text-center">
          <a 
            href="/order"
            className="btn btn-secondary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder" 
            // style={{backgroundColor : '#c58fff'}}
          >
          Lihat Daftar Pesanan
          </a>
        </div>
      </div>
    </div>
  )
}

export default PaymentEwallet;