import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom"

function Header(){
  const navigate = useNavigate()
  const [keywordSearch, setKeywordSearch] = useState()
  
  const searchHandler = (e) => {
    e.preventDefault()
    navigate('/product', {state:{keyword:keywordSearch}});
  }

  return (
    <header className="main_haeder header-sticky multi_item change_colorSearch py-3 border-0 d-flex justify-content-between">
      <div className="em_side_right">
        <button type="button" className="btn btn_menuSidebar item-show" width="13%" data-toggle="modal" data-target="#mdl_home_menu">
          <img src='assets/logo-small.png' height='30px' width='auto' alt="" />
        </button>
      </div>
      <div className="input_SaerchDefault mx-2" style={{'width' : '90%'}}>
        <form onSubmit={searchHandler} className="form-group with_icon mb-0">
          <div className="input_group">
            <input type="search" className="form-control form-control-sm border-0" placeholder="Cari tiket disini..." onChange={(e) => setKeywordSearch(e.target.value)} required />
            <div className="icon">
              <svg id="Iconly_Two-tone_Search" data-name="Iconly/Two-tone/Search" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                <g id="Search" transform="translate(2 2)">
                  <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#200e32" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                  <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#200e32" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" opacity="0.4" />
                </g>
              </svg>
            </div>
          </div>
        </form>
      </div>
      <div className="em_side_right">
        {/* <button type="button" className="btn btn_menuSidebar item-show" data-toggle="modal" data-target="#mdllButtons_share">
          <i className="ri-shopping-bag-3-line" />
        </button> */}
        <button type="button" className="btn btn_menuSidebar item-show" width="13%" data-toggle="modal" data-target="#mdl_home_menu">
          <i className="ri-menu-fill" />
        </button>
      </div>
    </header>
  )
}
export default Header