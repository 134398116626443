import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {updatePasswordReset, verifyCodeResetPassword} from '../../Api/Auth'

function ResetPassword(){
  const navigate = useNavigate()
  const {code} = useParams()

  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")
  const btnReset = useRef(null)
  const btnResetLoading = useRef(null)
  const [reqDisabled, setReqDisabled] = useState(true)
  const [validation, setValidation] = useState("")

  const inputRef = useRef(null)
  const [idUserCustomer, setIdUserCustomer] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const verifyCode = () => {
    const formData = new FormData()
    formData.append('code', code);
    verifyCodeResetPassword(formData).then((res) => {
      if(res.responseCode === 200000){
        setIdUserCustomer(res.responseData.id_usercustomer)
        inputRef.current.focus()
        setReqDisabled(false)
      }else{
        setValidation(res.responseMessage+'. You will redirected to Login Page in a few seconds')
        setTimeout(function() { 
          window.location.replace('/forgot-password');
        }, 5000)
      }
    })
  }

  const resetPassHandler = async (e) => {
    e.preventDefault()
    setValidation("")
    btnReset.current.className = 'd-none'
    btnResetLoading.current.className = 'd-block'
    const formData = new FormData()
    formData.append('id_usercustomer', idUserCustomer)
    formData.append('new_password', newPassword)
    formData.append('new_password_confirmation', confirmPassword)
    updatePasswordReset(formData).then((res) => {
      if(res.responseCode === 200000){
        setValidation(res.responseMessage+'. You will redirected to Login Page in a few seconds')
        setTimeout(function() { 
          navigate('/login')
        }, 5000)
      }else{
        setValidation(res.responseMessage)
        inputRef.current.focus()
        btnReset.current.className = 'd-block'
        btnResetLoading.current.className = 'd-none'
      }
    })
  }

  useEffect(() => {
    verifyCode()
  },[]);

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/login">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Login</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Reset Password
            </span>
          </div>
          <div className="em_side_right">
            <a href="/home" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all text-primary">
              Home
            </a>
          </div>
        </header>
        {/* form */}
        <section className="padding-20">
          <form onSubmit={resetPassHandler} className="padding-t-70">
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed show_hide_password">
                <div className="input_group">
                  <input ref={inputRef} disabled={reqDisabled} type="password" id="pass" className="form-control" placeholder="enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label htmlFor="pass">New Password</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed show_hide_password_confirm">
                <div className="input_group">
                  <input disabled={reqDisabled} type="password" className="form-control" placeholder="confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label>Confirm New Password</label>
              </div>
            </div>
            <div className="alert alert-light text-center" role="alert">
              {
                validation !== "" ? (<div>{validation}</div>) : ''
              }
            </div>
            <div className="buttons__footer text-center bg-transparent">
              <div className="env-pb">
                <div ref={btnReset} disabled={reqDisabled} className="d-block">
                  <button type="submit" className="btn bg-primary rounded-pill btn__default color-white">
                  <span className="color-white">Save Changes</span>
                    <div className="icon rounded-circle">
                      <i className="tio-chevron_right" />
                    </div>
                  </button>
                </div>
                <div ref={btnResetLoading} className="d-none">
                  <button type="button" className="btn bg-primary rounded-pill btn__default color-white" disabled>
                  <span className="color-white">Save Changes</span>
                    <div className="icon rounded-circle">
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
        {/* buttons__footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default ResetPassword;