import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 'Authorization' : `Bearer ${token}`,'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" }
}

const configNoAuth = {
  headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const productCategory = async () => {
  const req = await axios.get(`${baseUrl}/product-category`, configNoAuth)
  return req
}

export const productPromotionAtHome = async () => {
  const req = await axios.get(`${baseUrl}/product-promotion`, configNoAuth)
  return req
}

export const productList = async (format = 'all', sort = 'lastAdded', ds = '1', de = '1', province = 'all', priceFrom = 0, priceTo = 0, category = 0) => {
  const req = await axios.get(`${baseUrl}/product/${format}/${sort}/${ds}/${de}/${province}/${priceFrom}/${priceTo}/${category}`, configNoAuth)
  return req
}

export const productDetail = async (slug, businessType = 0) => {
  if(businessType == 0){
    const req = await axios.get(`${baseUrl}/product-detail/${slug}`, configNoAuth)
    return req
  }else{
    const req = await axios.get(`${baseUrl}/product-wisata-detail/${slug}`, configNoAuth)
    return req.data
  }
}

export const searchProduct = async (formData) => {
  const req = await axios.post(`${baseUrl}/search-product`, formData, configNoAuth)
  return req
}

export const productTicket = async (id) => {
  const req = await axios.get(`${baseUrl}/product-ticket/${id}`, configNoAuth)
  return req
}

export const formatEvent = async (productCategory) => {
  const req = await axios.get(`${baseUrl}/format-event/${productCategory}`, configNoAuth)
  return req
}

export const productOlahragaList = async (format = 'all', sort = 'lastAdded', ds = '1', de = '1', province = 'all', priceFrom = 0, priceTo = 0, category = 0) => {
  const req = await axios.get(`${baseUrl}/product-olahraga/${format}/${sort}/${ds}/${de}/${province}/${priceFrom}/${priceTo}/${category}`, configNoAuth)
  return req
}

export const productOlahragaDetail = async (id) => {
  const req = await axios.get(`${baseUrl}/product-olahraga-detail/${id}`, configNoAuth)
  return req
}

export const productOlahragaTicket = async (id) => {
  const req = await axios.get(`${baseUrl}/product-olahraga-ticket/${id}`, configNoAuth)
  return req
}

export const productWisataList = async () => {
  const req = await axios.get(`${baseUrl}/product-wisata`, configNoAuth)
  return req.data
}

export const productFee = async (formData) => {
  const req = await axios.post(`${baseUrl}/product-wisata-fee`, formData, configNoAuth)
  return req.data
}