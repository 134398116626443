import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import moment from 'moment'
import {getVaList, getEwalletList, payment, checkPromo} from '../../Api/Transaction';
import {getPoin} from '../../Api/Auth';

import {productTicket} from '../../Api/Product'
import {priceSplitter} from '../../Tools/Tools'
import {registeredTeam} from '../../Api/RegistrationSport';

function RegisterSportCheckout(){
  const navigate = useNavigate()
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState([])

  const dateNow = Date.now();
  const formatter_date = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric'})
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const qtyTicket = useRef(location.state.qtyTicket)
  const idEvent = useRef(location.state.idEvent)
  const idTicket = useRef(location.state.idTicket)
  const idRegistration = useRef(location.state.idRegistration)
  const slug = useRef(location.state.slug)
  const selectionDate = useRef(location.state.selectionDate)
  const idUser = localStorage.getItem('user-id')
  const userFullname = localStorage.getItem('fullname') ===  null ? '' : localStorage.getItem('fullname')
  const userPhone = localStorage.getItem('phone_number') ===  null ? '' : (localStorage.getItem('phone_number') ===  'null' ? '' : localStorage.getItem('phone_number'))
  const userEmail = localStorage.getItem('email') ===  null ? '' : localStorage.getItem('email')
  const userPoin = useRef(0)
  const poinUsed = useRef(0)

  const [visitorDiff, setVisitorDiff] = useState(false)
  const [visName, setVisName] = useState(userFullname)
  const [visPhone, setVisPhone] = useState(userPhone)
  const [visEmail, setVisEmail] = useState(userEmail)
  const itemDivVisitor = useRef(null)
  const alertDivVisitor = useRef(null)

  const btnPay = useRef(null)
  const btnPayLoading = useRef(null)

  const [methodSelected, setMethodSelected] = useState('')
  const methodPaymentNull = useRef(null)

  const [vaList, setVaList] = useState([])
  const [vaActive, setVaActive] = useState(null)
  const [ewalletList, setEwalletList] = useState([])
  const [ewalletActive, setEwalletActive] = useState([])
  const [methodDisabled, setMethodDisabled] = useState(false)
  const [bankCodeSelected, setBankCodeSelected] = useState()
  const [bankNameSelected, setBankNameSelected] = useState('')
  const [productData, setProductData] = useState([])

  const [insuranceInclude, setInsurancenInclude] = useState(false)

  const [promoCode, setPromoCode] = useState(null)
  const btnPromo = useRef(null)
  const btnPromoLoading = useRef(null)
  const promoApplied = useRef(null)
  const [usePromo, setUsePromo] = useState(false)
  const promoUsedType = useRef('')
  const promoUsedTypeAmount = useRef(0)
  const promoUsedAmount = useRef(0)
  const [promoDisabled, setPromoDisabled] = useState(false)
  const alertPromoCodeFail = useRef(null)
  const alertPromoCodeSuccess = useRef(null)

  const [usePoin, setUsePoin] = useState(false)

  const priceTicket = useRef(0)
  const disc = useRef(0)
  const priceAfterDisc = useRef(0)
  const amount = useRef(0)
  const insurancePrice = useRef(0)
  const taxPrice = useRef(0)
  const final_amount = useRef(0)
  const poin_reward = useRef(0)

  const ticketUnavailableRef = useRef(null)

  const [team, setTeam] = useState([])
  const [personilTeam, setPersonilTeam] = useState([])
  const [personilView, setPersonilView] = useState([])

  const getUserPoin = () => {
    getPoin().then((res) => {
      if(res.status == 200){
        userPoin.current = res.data
        reqRegTeam()
      }
    })
  }

  const reqDetail = async () => {
    productTicket(idTicket.current).then((res) => {
      if(res.status == 200){
        setProductData(res.data[0])
        setImageUrl(res.data[0].product_name === 1 ? process.env.REACT_APP_ASSETS_PRODUCT_WISATA : (res.data[0].product_name === 2 ? process.env.REACT_APP_ASSETS_PRODUCT_EVENT : process.env.REACT_APP_ASSETS_PRODUCT_SPORT))
      }
    })
  }

  const reqRegTeam = async () => {
    registeredTeam(idRegistration.current).then((res) => {
      if(res.status == 200){
        setTeam(res.data.team)
        setPersonilTeam(res.data.personil)
        setLoaderDisplay('none')
      }
    })
  }

  const reqVaList = async () => {
    getVaList().then((res) => {
      if(res.status == 200){
        setVaList(res.data)
      }
    })
  }

  const reqEwalletList = async () => {
    getEwalletList().then((res) => {
      if(res.status == 200){
        setEwalletList(res.data)
      }
    })
  }

  const qrSelected = () => {
    setVaActive('QR')
    setEwalletActive('')
    setMethodSelected('QRIS')
    setBankCodeSelected('')
    setBankNameSelected('')
  }

  const bankSelected = (key) => {
    setVaActive(key)
    setEwalletActive('')
    setMethodSelected('Virtual Account')
    setBankCodeSelected(vaList[key].code)
    setBankNameSelected(vaList[key].name)
  }

  const ewalletSelected = (key) => {
    setEwalletActive(key)
    setVaActive('')
    setMethodSelected('E-Wallet')
    setBankCodeSelected(ewalletList[key].channel_code)
    setBankNameSelected(ewalletList[key].name)
  }

  const paymentHandler = (e) => {
    e.preventDefault()
    ticketUnavailableRef.current.className = 'd-none'
    methodPaymentNull.current.className = 'd-none'
    if(methodSelected != ''){
      methodPaymentNull.current.className = 'd-none'
      btnPay.current.className = 'd-none'
      btnPayLoading.current.className = 'd-block'
      setMethodDisabled(true)
      const formData = new FormData()
      formData.append('id_customer', idUser)
      formData.append('id_event', idEvent.current)
      formData.append('id_ticket', idTicket.current)
      formData.append('qty', qtyTicket.current)
      formData.append('selection_date', selectionDate.current)
      formData.append('visitor_diff', visitorDiff ? 0 : 1)
      formData.append('visitor_name', visName)
      formData.append('visitor_phone', visPhone)
      formData.append('visitor_email', visEmail)
      formData.append('visitor_identity_number', 0)
      formData.append('insurance_include', insuranceInclude ? 1 : 0)
      formData.append('use_promo', usePromo ? 1 : 0)
      formData.append('promo_code', usePromo ? promoCode : null)
      formData.append('promo_amount', usePromo ? promoUsedAmount.current : 0)
      formData.append('use_poin', usePoin ? 1 : 0)
      if(methodSelected === 'Virtual Account'){
        formData.append('method_payment', 'VA')
        formData.append('bank_code', bankCodeSelected)
        formData.append('is_closed', true)
        formData.append('expected_amount', final_amount.current)
        payment(formData).then((res) => {
          setMethodDisabled(false)
          btnPay.current.className = 'd-block'
          btnPayLoading.current.className = 'd-none'
          if(res.data.status === 'Failed'){
            ticketUnavailableRef.current.className = 'd-block'
          }else{
            window.$('#mdl_payment_method').modal('hide');
            navigate('/payment', {state: { 
                                            accountNumber : res.data.account_number,
                                            bankCode : res.data.bank_code,
                                            bankName : bankNameSelected,
                                            expectedAmount : res.data.expected_amount,
                                            expirationDate : res.data.expiration_date,
                                            referensi : res.data.external_id,
                                            event_name : productData.event_name,
                                            ticket_name : productData.ticket_name,
                                            topik_name :productData.topik_name
                                          }})
          }
        })
      }else if(methodSelected === 'QRIS'){
        formData.append('method_payment', 'QRIS')
        formData.append('amount', final_amount.current)
        payment(formData).then((res) => {
          setMethodDisabled(false)
          btnPay.current.className = 'd-block'
          btnPayLoading.current.className = 'd-none'
          if(res.data.status === 'Failed'){
            ticketUnavailableRef.current.className = 'd-block'
          }else{
            window.$('#mdl_payment_method').modal('hide')
            navigate('/payment-qris', {state: {
                                                qr_id : res.data.qr_id,
                                                qr_string : res.data.qr_string,
                                                expectedAmount : res.data.expected_amount,
                                                referensi : res.data.external_id,
                                                event_name : productData.event_name,
                                                ticket_name : productData.ticket_name,
                                                topik_name :productData.topik_name
                                              }})
          }
        })
      }else if(methodSelected === 'E-Wallet'){
        formData.append('method_payment', 'E-Wallet')
        formData.append('amount', final_amount.current)
        formData.append('channel_code', bankCodeSelected)
        console.log(Array.from(formData.entries()))
        payment(formData).then((res) => {
          setMethodDisabled(false)
          btnPay.current.className = 'd-block'
          btnPayLoading.current.className = 'd-none'
          if(res.data.status === 'Failed'){
            ticketUnavailableRef.current.className = 'd-block'
          }else{
            window.$('#mdl_payment_method').modal('hide')
            navigate('/payment-ewallet', {state: {
                                                    expectedAmount : res.data.expected_amount,
                                                    desktopWebUrl : res.data.desktop_web_checkout_url,
                                                    mobileWebUrl : res.data.mobile_web_checkout_url,
                                                    mobileDeeplinkUrl : res.data.mobile_deeplink_checkout_url,
                                                    channelCode : bankNameSelected,
                                                    event_name : productData.event_name,
                                                    ticket_name : productData.ticket_name,
                                                    topik_name :productData.topik_name
                                                  }})
          }
        })
      }
    }else{
      methodPaymentNull.current.className = 'd-block'
    }
  }

  const promoHandler = (e) => {
    e.preventDefault()
    const formData = new FormData()
    btnPromo.current.className = 'd-none'
    btnPromoLoading.current.className = 'd-block'
    alertPromoCodeFail.current.className = "d-none"
    alertPromoCodeSuccess.current.className = "d-none"
    formData.append('id_ticket', idTicket.current)
    formData.append('promo_code', promoCode)
    checkPromo(formData).then((res) => {
      if(res.status == 200){
        if(res.data.validation === false){
          alertPromoCodeFail.current.className = "d-block"
          alertPromoCodeSuccess.current.className = "d-none"
        }else{
          if(amount.current >= res.data.data_promo.minimum_transaksi && amount.current <= res.data.data_promo.maksimum_transaksi){
            setUsePromo(true)
            promoUsedType.current = res.data.data_promo.type
            promoUsedTypeAmount.current = res.data.data_promo.amount
            promoUsedAmount.current = res.data.data_promo.type === 'procentage' ? (parseInt(res.data.data_promo.amount)/100*parseInt(amount.current)) : res.data.data_promo.amount
            final_amount.current = parseInt(final_amount.current)-parseInt(promoUsedAmount.current)
            setPromoDisabled(true)
            alertPromoCodeSuccess.current.className = "d-block"
            alertPromoCodeFail.current.className = "d-none"
            promoApplied.current.className = "d-block"
          }else{
            alertPromoCodeFail.current.className = "d-block"
            alertPromoCodeSuccess.current.className = "d-none"
          }
        }
      }
      btnPromo.current.className = 'd-block'
      btnPromoLoading.current.className = 'd-none'
    })
  }

  const rmPromoHandler = () => {
    setUsePromo(false)
    setPromoCode(null)
    final_amount.current = parseInt(final_amount.current)+parseInt(promoUsedAmount.current)
    promoUsedAmount.current = 0
    setPromoDisabled(false)
    alertPromoCodeSuccess.current.className = "d-none"
    promoApplied.current.className = "d-none"
  }

  const usePoinHandler = () => {
    if(usePoin){
      setUsePoin(false)
      userPoin.current = poinUsed.current
      poinUsed.current = 0
      final_amount.current = parseInt(final_amount.current)+parseInt(userPoin.current)
    }else{
      setUsePoin(true)
      poinUsed.current = userPoin.current
      userPoin.current = 0
      final_amount.current = final_amount.current-poinUsed.current
    }
  }
  
  const toProductDetail = (slug) => {
    navigate('/product-detail/'+slug)
  }

  const viewPersonil = () => {
    if(personilTeam.length !== 0){
      console.log(personilTeam)
      setPersonilView(
        personilTeam.map((person, personKey) => {
          return (
            <div className="emPage__invoiceDetails px-1 py-0">
              <div className="emfooter__invoice mt-3">
                <div className="emBox__paymentDetails p-2">
                  <span className="weight-600 d-block text-left mb-2">Anggota #{personKey+1}</span>
                  <ul>
                    <li>
                      <span>Nama Lengkap</span>
                      <span>{personilTeam[personKey].fullname}</span>
                    </li>
                    <li>
                      <span>Tanggal Lahir</span>
                      <span>{moment(personilTeam[personKey].dob).format('DD MMM YYYY')}</span>
                    </li>
                    <li>
                      <span>Usia</span>
                      <span>{moment().diff(personilTeam[personKey].dob, 'years')} Tahun</span>
                    </li>
                    <li>
                      <span>Kartu Identitas</span>
                      <a href="#" target="_blank">{personilTeam[personKey].card_id}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
        }) 
      )
    }
  }

  useEffect(() => {
    viewPersonil()
  }, [personilTeam])

  useEffect(() => {
    priceTicket.current = productData.ticket_price_bayar
    var start_date_discount = productData.start_date_discount === '' ? '1970-01-01' : productData.start_date_discount
    var start_end_discount = productData.end_date_discount === '' ? '1970-01-01' : productData.end_date_discount
    var discStart = new Date(start_date_discount);
    var discEnd = new Date(start_end_discount);
    if(
      productData.discount === 'Ya' 
      && moment().isAfter(discStart)
      && moment().isBefore(discEnd)
    ){
      if(productData.discount_type === 'procentage'){
        disc.current = (productData.discount_procentage/100)*productData.ticket_price_bayar
      }else{
        disc.current = productData.discount_rp
      }
    }

    priceAfterDisc.current = priceTicket.current-disc.current
    amount.current = priceAfterDisc.current*qtyTicket.current

    if(productData.biaya_pajak_dikenakan_ke == 'Cust'){
      taxPrice.current = productData.biaya_pajak/100*amount.current
    }

    final_amount.current = amount.current+taxPrice.current
    poin_reward.current = productData.poin_reward*qtyTicket.current
  }, [productData])

  useEffect(() => {
    reqVaList()
    reqEwalletList()
    reqDetail()
    getUserPoin()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section> 
      <div id="content">
        <header className="main_haeder header-sticky multi_item shadow-sm">
          <div className="em_side_right">
            <span className="rounded-circle d-flex align-items-center cursor-pointer" onClick={() => toProductDetail(slug.current)}>
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </span>
          </div>
          <div className="title_page">
            <div className="page_name_checkout">Pembayaran Registrasi
            </div>
          </div>
        </header>
        <section className="emPage__basket npPkg__basket default" style={{paddingBottom : '100px'}}>
          <div className="emPage___profile accordion accordion_inset" id="accordionExample-inset">
            <div className="emBody__navLink">
              <ul>
                <li className="item margin-b-5">
                  <div id="headingOne-inset">
                    <div className="nav-link main_item" data-toggle="collapse" data-target="#collapseOne-inset" aria-expanded="false" aria-controls="collapseOne-inset">
                      <div className="media align-items-center">
                        <div className="ico">
                          <img className="imgList w-60 h-51 rounded-4" style={{'object-fit' : 'cover', 'cursor' : 'default'}} src={imageUrl+productData.id_event+'/'+productData.foto_banner} alt="" />
                        </div>
                        <div className="media-body my-auto">
                          <div className="txt" style={{'cursor' : 'default'}}>
                            <h3 className="mb-0">{productData.event_name}</h3>
                            <span className="size-14 color-primary">{productData.ticket_name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="side_right">
                        <i className="tio-chevron_right -arrwo"></i>
                      </div>
                    </div>
                  </div>
                  <div id="collapseOne-inset" className="collapse" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset">
                    <div className="card-body pt-0 pb-2">
                      <div className="pt-2 border-top">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                          </svg>
                          {productData.start_date === productData.end_date ? productData.start_date : productData.start_date+' - '+productData.end_date}
                        </span>
                      </div>
                      <div className="mt-1">
                        <span className="d-inline-block">
                          <svg className="w-16 h-16 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                          </svg>
                          {productData.location_name+', '+productData.address+', '+productData.kota+', '+productData.province}
                        </span>
                      </div>
                      <div className="mt-2 border-top pt-2">
                        <span className="size-14 mb-1">Info Penting</span>
                        <div className="color-text ml-4">
                          <div dangerouslySetInnerHTML={{__html: productData.info_penting}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Detail Pendaftar</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <span className="d-block font-weight-bold text-capitalize">{userFullname}</span>
                <span className="d-block color-text">{userPhone}</span>
                <span className="d-block color-text">{userEmail}</span>
              </div>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Detail Tim</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div ref={itemDivVisitor} className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="itemSwitch d-flex py-1">
                  <label htmlFor="toggleTwo">
                    <span className="size-14">Nama Tim</span>
                  </label>
                  <div className="em_toggle ml-auto">
                    <span className="size-14 weight-600">{team.name_team}</span>
                  </div>
                </div>
                <div className="itemSwitch d-flex border-bottom py-1">
                  <label htmlFor="toggleTwo">
                    <span className="size-14">Jumlah Anggota</span>
                  </label>
                  <div className="em_toggle ml-auto">
                    <span className="size-14 weight-600">{team.count_peserta} Orang</span>
                  </div>
                </div>
                <div id="headingOne-inset">
                  <div className="nav-link main_item d-flex px-0 py-2" data-toggle="modal" data-target="#mdl_personil">
                    <div className="media align-items-center">
                      <div className="media-body my-auto">
                        <div className="size-14" style={{'cursor' : 'default'}}>
                          <span className="mb-0 color-primary weight-600 text-capitalize">Detail Anggota</span>
                        </div>
                      </div>
                    </div>
                    <div className="side_right ml-auto">
                      <i className="tio-chevron_right -arrwo"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Biaya Lain-Lain</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14">Tax</span>
                    {
                      productData.biaya_pajak_dikenakan_ke === 'Cust' ? (
                        // <span className="size-12 color-text d-block">0.5%</span>
                        ''
                      ) : (
                        <span className="size-12 color-text d-block">Biaya ditanggung oleh pihak EO</span>
                      )
                    }
                  </div>
                  <div className="em_toggle ml-auto">
                    <span className="size-14">Rp {priceSplitter(taxPrice.current)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-snow">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Kode Promo / Voucher</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 shadow-sm">
                <div className="itemSwitch py-1">
                  <form className="d-flex" onSubmit={promoHandler}>
                    <input disabled={promoDisabled} type="text" className="form-control size-14" placeholder="Masukan kodemu" value={promoCode === null ? '' : promoCode} onChange={(e) => setPromoCode(e.target.value)} required/>
                    <div className="em_toggle ml-auto pl-2">
                      <div ref={btnPromo} className="d-block">
                        <button disabled={promoDisabled} type="submit" className="btn btn-primary size-14">
                          Cek
                        </button>
                      </div>
                      <div ref={btnPromoLoading} className="d-none">
                      <button className="btn btn-primary size-14" type="button" disabled>
                        <span className="spinner-border spinner-border-sm color-white" role="status" aria-hidden="true" />
                        <span className="sr-only">Loading...</span>
                      </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div ref={alertPromoCodeFail} className="d-none">
                  <span className="size-12 color-red mt-1">Kode promo tidak berlaku.</span>
                </div>
                <div ref={alertPromoCodeSuccess} className="d-none">
                  <span className="size-12 color-blue mt-1">Kode promo berhasil digunakan.</span>
                </div>
                <div ref={promoApplied} className="d-none">
                  <div className="d-flex justify-content-between align-items-center p-2 rounded-5 border">
                    <div className="d-flex flex-column">
                      <span className="color-comment">Promo Apply</span>
                      <span className="color-comment size-12 color-green">
                        {
                          promoUsedType.current === 'procentage' ? (
                            '- '+promoUsedTypeAmount.current+'%'
                          ) : (
                            '- Rp '+promoUsedTypeAmount.current
                          )
                        }
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="size-14 color-green d-block">- Rp {priceSplitter(promoUsedAmount.current)}</span>
                      <span onClick={() => {rmPromoHandler()}}>
                        <svg className="w-22 h-22 ml-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18 17.94 6M18 18 6.06 6" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-snow padding-b-20">
            <div className="emTitle_co padding-r-20 padding-l-20 padding-t-20">
              <h2 className="size-14 weight-600 color-secondary">Gunakan Poin Untuk Pembayaran</h2>
            </div>
            <div className="em_masonry__layout bg-snow em_list_layout pt-0">
              <div className="item em_item_product item_list d-block mb-0 bg-gradient shadow-sm">
                <div className="itemSwitch d-flex py-1 align-items-center">
                  <div>
                    <span className="size-14">Poinmu Saat Ini</span>
                    <img src="assets/star.png" width={'auto'} height={14} alt="" className="ml-1"/>
                    <span className="size-12 color-text d-block weight-700">{priceSplitter(userPoin.current)}</span>
                  </div>
                  <div className="em_toggle ml-auto">
                    <button type="button" className="btn btn-toggle toggle_md" data-toggle="button" aria-pressed="false" autoComplete="off" id="togglePoin" onClick={usePoinHandler}>
                      <div className="handle"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="buttons__footer" style={{bottom : 0}}>
          <div className="size-16 weight-600 color-primary mb-2" style={{'cursor' : 'default'}} data-toggle="modal" data-target="#mdl_detail_harga">
            <span className="color-text">Rp</span> {priceSplitter(final_amount.current)}
            <svg className="w-16 h-16 ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7"/>
            </svg>
          </div>
          <div className="env-pb text-center">
            <button type="button" className="btn bg-primary w-100 color-white text-center justify-content-center px-5" data-toggle="modal" data-target="#mdl_payment_method">
              <b>Pilih pembayaran</b>
            </button>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_payment_method" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-18 weight-600 color-secondary m-0">Metode Pembayaran</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button disabled={methodDisabled} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body border-top overflow-auto" style={{height : '60vh', backgroundColor : '#f7f7f8'}}>
              <div className="emPage___profile accordion accordion_inset py-0 mt-3" id="accordionExample-inset-payment">
                <div className="emBody__navLink">
                  <ul>
                    <li className="item margin-b-10">
                      <div id="headingOne-inset">
                        <div className="nav-link main_item" style={{borderRadius : 0}} data-toggle="collapse" data-target="#collapseOne-inset1" aria-expanded="false" aria-controls="collapseOne-inset1">
                          <div className="media align-items-center" style={{width : '40%'}}>
                            <div className="media-body my-auto">
                              <div className="txt">
                                <h3 className="mb-0">Scan QRIS Code</h3>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end" style={{width : '50%'}}>
                            <img className="" src="/assets/img/qris/qris-logo.svg" alt="" height={'12px'} width={'auto'}/>
                          </div>
                          <div className="side_right w-20">
                            <i className="tio-chevron_right -arrwo"></i>
                          </div>
                        </div>
                      </div>
                      <div id="collapseOne-inset1" className="collapse" aria-labelledby="headingOne-inset" data-parent="#accordionExample-inset-payment">
                        <div className="card-body bg-white py-0">
                          <section className="emSection__payment px-0 pt-3 mt-1">
                            <div className="credite_saved itemSingle">
                              <button 
                                disabled={methodDisabled} 
                                type="button" 
                                className={vaActive === 'QR' ? 'btn item item_credit selected': "btn item item_credit"}
                                onClick={() => qrSelected()}
                              >
                                <div className="media align-items-center">
                                  <div className="icon">
                                    <img src="/assets/img/qris/qris-logo.svg" alt="" height={'20px'} width={'auto'}/>
                                  </div>
                                  <div className="media-body">
                                    <div className="txt">
                                      <span className="size-14 text-body">QRIS Code</span>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </section>
                        </div>
                      </div>
                    </li>
                    <li className="item margin-b-10">
                      <div id="headingOne-inset2">
                        <div className="nav-link main_item" style={{borderRadius : 0}} data-toggle="collapse" data-target="#collapseOne-inset2" aria-expanded="false" aria-controls="collapseOne-inset2">
                          <div className="media align-items-center" style={{width : '40%'}}>
                            <div className="media-body my-auto">
                              <div className="txt">
                                  <h3 className="mb-0">Virtual Account</h3>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end" style={{width : '50%'}}>
                            <img className="mr-1" src="/assets/img/banks/bca-logo.svg" alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src="/assets/img/banks/bni-logo.svg" alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src="/assets/img/banks/bri-logo.svg" alt="" height={'9px'} width={'auto'}/>
                          </div>
                          <div className="side_right">
                            <i className="tio-chevron_right -arrwo"></i>
                          </div>
                        </div>
                      </div>
                      <div id="collapseOne-inset2" className="collapse" aria-labelledby="headingOne-inset2" data-parent="#accordionExample-inset-payment">
                        <div className="card-body bg-white py-0">
                          <section className="emSection__payment px-0 pt-3 mt-1">
                            <div className="credite_saved itemSingle">
                              {
                                vaList.map((va, Key) => {
                                  var bankLogo = '/assets/img/banks/'+vaList[Key].code.toLowerCase()+'-logo.svg'
                                    return (
                                      <button 
                                        key={Key}
                                        disabled={methodDisabled} 
                                        type="button" 
                                        className={vaActive === Key ? "btn item item_credit selected p-3" : "btn item item_credit p-3"} 
                                        onClick={() => bankSelected(Key)
                                      }>
                                        <div className="media align-items-center">
                                          <div className="icon">
                                            <img src={bankLogo} alt="" width={50} height={'auto'}/>
                                          </div>
                                          <div className="media-body">
                                            <div className="txt">
                                              <span className="size-14 text-body">{vaList[Key].name}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    )
                                })
                              }
                            </div>
                          </section>
                        </div>
                      </div>
                    </li>
                    <li className="item margin-b-10">
                      <div id="headingOne-inset3">
                        <div className="nav-link main_item" style={{borderRadius : 0}} data-toggle="collapse" data-target="#collapseOne-inset3" aria-expanded="false" aria-controls="collapseOne-inset3">
                          <div className="media align-items-center" style={{width : '40%'}}>
                            <div className="media-body my-auto">
                              <div className="txt">
                                  <h3 className="mb-0">E-Wallet</h3>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end" style={{width : '50%'}}>
                            <img className="mr-1" src="/assets/img/e-wallet/ovo-logo.svg" alt="" height={'9px'} width={'auto'}/>
                            <img className="mr-1" src="/assets/img/e-wallet/dana-logo.svg" alt="" height={'10px'} width={'auto'}/>
                            <img className="mr-1" src="/assets/img/e-wallet/shopeepay-logo.svg" alt="" height={'10px'} width={'auto'}/>
                          </div>
                          <div className="side_right">
                            <i className="tio-chevron_right -arrwo"></i>
                          </div>
                        </div>
                      </div>
                      <div id="collapseOne-inset3" className="collapse" aria-labelledby="headingOne-inset3" data-parent="#accordionExample-inset-payment">
                        <div className="card-body bg-white py-0">
                          <section className="emSection__payment px-0 pt-3 mt-1">
                            <div className="credite_saved itemSingle">
                              {
                                ewalletList.map((va, Key) => {
                                  var bankLogo = '/assets/img/e-wallet/'+ewalletList[Key].name.toLowerCase()+'-logo.svg'
                                    return (
                                      <button 
                                        key={Key}
                                        disabled={methodDisabled} 
                                        type="button" 
                                        className={ewalletActive === Key ? "btn item item_credit selected p-3" : "btn item item_credit p-3"} 
                                        onClick={() => ewalletSelected(Key)
                                      }>
                                        <div className="media align-items-center">
                                          <div className="icon">
                                            <img src={bankLogo} alt="" width={50} height={'auto'}/>
                                          </div>
                                          <div className="media-body">
                                            <div className="txt">
                                              <span className="size-14 text-body">{ewalletList[Key].name}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    )
                                })
                              }
                            </div>
                          </section>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="h-80"></div>
            </div>
            <div className="modal-footer">
              <div className=" pt-0" style={{bottom : 0, width : '100%'}}>
                <div className="env-pb text-center">
                  <p className="color-text size-13 text-center my-2">
                    Kamu akan transfer 
                    <span className="color-secondary"> Rp {priceSplitter(final_amount.current)} </span> 
                    dengan {methodSelected === 'QRIS' ? 'scan code QRIS' : methodSelected}
                    <span className="color-secondary"> {bankNameSelected}</span>
                  </p>
                  <p ref={methodPaymentNull} className="d-none">
                    <span className="color-red size-13 text-center mb-0 my-2 d-block">Pilih metode pembayaran</span>
                  </p>
                  <p ref={ticketUnavailableRef} className="d-none">
                    <span className="color-red size-13 text-center mb-0 my-2 d-block">Transaksi kamu gagal, jumlah tiket yang kamu pesan melebihi sisa kuota.</span>
                  </p>
                  <div ref={btnPay} className="d-block">
                    <button className="btn bg-primary rounded-pill btn__default" onClick={paymentHandler}>
                      <span className="color-white d-inline-block mr-5"><b>Bayar</b></span>
                      <div className="icon rounded-circle">
                        <i className="tio-chevron_right"/>
                      </div>
                    </button>
                  </div>
                  <div ref={btnPayLoading} className="d-none">
                    <button className="btn bg-primary rounded-pill btn__default" disabled>
                      <span className="color-white d-inline-block mr-5"><b>Bayar</b></span>
                      <div className="icon rounded-circle">
                        <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_personil" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Detail Anggota</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body px-3 overflow-auto pb-3" style={{height : '75vh'}}>
              {personilView}
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_detail_harga" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Detail Harga</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="emPage__basket npPkg__basket default py-3">
                <div className="detailsTotally p-0">
                  <ul>
                    <li>
                      <span>Jumlah Tiket</span>
                      <span className="font-weight-normal">{qtyTicket.current} Pax</span>
                    </li>
                    <li>
                      <span>Harga /tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter(priceTicket.current)}</span>
                    </li>
                    <li>
                      <span>Diskon</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(disc.current)}</span>
                    </li>
                    <li>
                      <span>Total Diskon</span>
                      <span className="font-weight-normal">Rp {priceSplitter(priceAfterDisc.current)}</span>
                    </li>
                    <li>
                      <span>Total Harga Tiket</span>
                      <span className="font-weight-normal">Rp {priceSplitter(priceAfterDisc.current*qtyTicket.current)}</span>
                    </li>
                    <div className="dividar" />
                    <li>
                      <span>
                        Tax {
                          productData.biaya_pajak_dikenakan_ke === 'Cust' ? (
                            // <span className="size-12 color-text d-block">0.5%</span>
                            ''
                          ) : ''
                        }
                      </span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(taxPrice.current)}</span>
                    </li>
                    <li>
                      <span>Biaya Asuransi</span>
                      <span className="font-weight-normal text-muted">Rp {priceSplitter(insurancePrice.current)}</span>
                    </li>
                    <li>
                      <span>Potongan Kode Promo</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(promoUsedAmount.current)}</span>
                    </li>
                    <li>
                      <span>Potongan Poinmu</span>
                      <span className="font-weight-normal text-success">- Rp {priceSplitter(poinUsed.current)}</span>
                    </li>
                    <div className="dividar" />
                    <li className="mb-1">
                      <span className="size-16 text_total">Total</span>
                      <span className="number_total size-16">Rp {priceSplitter(final_amount.current)}</span>
                    </li>
                    <li className=" d-flex justify-content-end text-end">
                      <span className="mb-1 color-text size-12 mt-0">
                        <img src={window.location.origin + "/assets/star.png"} width={'auto'} height={14} alt="" className="mr-1 margin-b-9"/>
                        Poin
                        <span className="ml-1 text-primary size-12 d-inline">{poin_reward.current}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_detail_insurance" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 text-left">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Perlindungan Ekstra</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body overflow-auto" style={{height : '70vh'}}>
              <div className="emPage__basket npPkg__basket default py-3">
                <div className="detailsTotally p-0">
                  <div dangerouslySetInnerHTML={{__html: productData.benefit}} />
                  <table className="table table-bordered table-striped text-center mt-2">
                    <thead>
                      <th className="w-50 p-1">Manfaat</th>
                      <th className="w-50 p-1">Kompensasi</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-1">-</td>
                        <td className="p-1">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterSportCheckout;