import React, {Component} from 'react';

class ModalShare extends Component{
  render(){
    return (
      <div>
        <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdllButtons_share" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content rounded-15">
              <div className="modal-body rounded-15 p-0">
                <div className="emBK__buttonsShare icon__share padding-20">
                  <button type="button" className="btn" data-sharer="facebook" data-hashtag="hashtag" data-url="https://orinostudio.com/nepro/">
                    <div className="icon bg-facebook rounded-10">
                      <i className="tio-facebook_square" />
                    </div>
                  </button>
                  <button type="button" className="btn" data-sharer="telegram" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/" data-to="+44555-5555">
                    <div className="icon bg-telegram rounded-10">
                      <i className="tio-telegram" />
                    </div>
                  </button>
                  <button type="button" className="btn" data-sharer="skype" data-url="https://orinostudio.com/nepro/" data-title="Checkout Nepro!">
                    <div className="icon bg-skype rounded-10">
                      <i className="tio-skype" />
                    </div>
                  </button>
                  <button type="button" className="btn" data-sharer="linkedin" data-url="https://orinostudio.com/nepro/">
                    <div className="icon bg-linkedin rounded-10">
                      <i className="tio-linkedin_square" />
                    </div>
                  </button>
                  <button type="button" className="btn" data-sharer="twitter" data-title="Checkout Nepro!" data-hashtags="pwa, Nepro, template, mobile, app, shopping, ecommerce" data-url="https://orinostudio.com/nepro/">
                    <div className="icon bg-twitter rounded-10">
                      <i className="tio-twitter" />
                    </div>
                  </button>
                  <button type="button" className="btn" data-sharer="whatsapp" data-title="Checkout Nepro!" data-url="https://orinostudio.com/nepro/">
                    <div className="icon bg-whatsapp rounded-10">
                      <i className="tio-whatsapp_outlined" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ModalShare