import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Footer from '../../Components/Footer';
import {logout, getPoinUser} from '../../Api/Auth';
import { priceSplitter } from '../../Tools/Tools';

function MyProfile(){
  const navigate = useNavigate()
  const state = useLocation()
  
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const userFullname = localStorage.getItem('fullname')
  const userPhoneNumber = localStorage.getItem('phone_number')
  const userEmail = localStorage.getItem('email')
  const [poin, setPoin] = useState(0)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  const getUserPoin = () => {
    getPoinUser().then((res) => {
      if(res.responseCode == 200000){
        setPoin(res.responseData.poin_reward)
      }
    })
  }

  const logOutHandler = async () => {
    logout().then((response) => {
      if(response){
        navigate('/home')
      }
    })
  };
  
  const toHistoryOrder=()=>{
    navigate('/history', {state:{backPage:'profile'}});
  }
  
  const toChangePassword=()=>{
    navigate('/change-password');
  }

  useEffect(() => {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    checkUserToken()
    getUserPoin()
  },[]);

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" className="bg-snow" style={{backgroundColor: '#f7f7f8'}}>
        <header className="main_haeder header-sticky multi_item header-white">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Home</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name color-white">
              My Profile
            </span>
          </div>
        </header>
        <section className="background_header gradient" style={{height:'160px'}}>
        </section>
        <main className="emPage__public bg-snow">
          <section className="npBalabce_section padding-20 pb-0">
            <div className="content_balance bg-white border border-snow" style={{marginTop:'-80px'}}>
              <div className="txt">
                <span className="d-block weight-700 size-14 text-capitalize text-logo-gradient">{userFullname} </span>
                <span className="color-comment size-14 text-nowrap d-block">
                  {userPhoneNumber}
                </span>
              </div>
              <div className="action">
                <a href="/personal-details" className="btn ">
                  Detail Akun
                </a>
              </div>
            </div>
          </section>
          <section className="padding-20 emTransactions__page np_Package_ac style_two">
            <div className="border-text margin-b-30">
              <div className="lined">
                <span className="text">Ringkasan</span>
              </div>
            </div>
            <div className="emBK__transactions">
              <div className="item_trans p-3" data-toggle="modal" data-target="#mdllDetailsTrans">
                <div className="media sideLeft">
                  <div className="icon_img border border-snow fill-primary">
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  </div>
                  <div className="media-body my-auto">
                    <h4>Poin Reward</h4>
                  </div>
                </div>
                <div className="sideRight">
                  <p className="mb-0">
                    {priceSplitter(poin)}
                  </p>
                </div>
              </div>
              <div className="item_trans p-3" onClick={() => toHistoryOrder()}>
                <div className="media sideLeft">
                  <div className="icon_img border border-snow fill-primary">
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-3 8a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-2-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H9Zm2 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-2-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H9Z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="media-body my-auto">
                    <h4 className="">Riwayat Transaksi</h4>
                  </div>
                </div>
                <div className="sideRight">
                </div>
              </div>
              <div className="item_trans p-3" onClick={() => toChangePassword()}>
                <div className="media sideLeft">
                  <div className="icon_img border border-snow fill-primary">
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="media-body my-auto">
                    <h4>Password Login</h4>
                  </div>
                </div>
                <div className="sideRight">
                </div>
              </div>
              <div className="item_trans p-3" data-toggle="modal" data-target="#mdl_information_others">
                <div className="media sideLeft">
                  <div className="icon_img border border-snow fill-primary">
                  <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeWidth={3} d="M6 12h.01m6 0h.01m5.99 0h.01" />
                  </svg>
                  </div>
                  <div className="media-body my-auto">
                    <h4>Informasi Lainnya</h4>
                  </div>
                </div>
                <div className="sideRight">
                </div>
              </div>
              <div className="item_trans p-3" onClick={logOutHandler}>
                <div className="media sideLeft">
                  <div className="icon_img border border-snow fill-primary">
                    <svg className="w-6 h-6 text-gray-800 rotate-180 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2" />
                  </svg>
                  </div>
                  <div className="media-body my-auto">
                    <h4>Keluar</h4>
                  </div>
                </div>
                <div className="sideRight">
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer/>

      <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_information_others" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header padding-l-20 padding-r-20 border-bottom">
              <div className="itemProduct_sm">
                <h1 className="size-16 weight-600 color-secondary m-0">Informasi Lainnya</h1>
              </div>
              <div className="absolute right-0 padding-r-20">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
            </div>
            <div className="modal-body p-0" style={{minHeight : '10vh'}}>
              <div className="emPage__basket npPkg__basket default py-1">
                <div className="em__pkLink accordion bg-white" id="accordionExample5">
                  <ul className="nav__list with_border fullBorder mb-4">
                    <li>
                      <div>
                        <a className="item-link main_item" href="/about">
                          <div className="group">
                            <div>
                              <span className="path__name">Tentang Juara Tiket</span>
                            </div>
                          </div>
                          <div className="group">
                            <span className="short__name" />
                            <i className="tio-chevron_right -arrwo" />
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <a className="item-link main_item" href="/privacy-policy">
                          <div className="group">
                            <div>
                              <span className="path__name">Kebijakan Privasi</span>
                            </div>
                          </div>
                          <div className="group">
                            <span className="short__name" />
                            <i className="tio-chevron_right -arrwo" />
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <a className="item-link main_item" href="term-condition">
                          <div className="group">
                            <div>
                              <span className="path__name">Syarat & Ketentuan</span>
                            </div>
                          </div>
                          <div className="group">
                            <span className="short__name" />
                            <i className="tio-chevron_right -arrwo" />
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="item-link main_item">
                          <div className="group">
                            <div>
                              <span className="path__name">App Version</span>
                            </div>
                          </div>
                          <div className="group">
                            <span className="color-text">1.0.0</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyProfile;