import moment from 'moment'

export const priceSplitter = (number) => {
  // const numberFormat = number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  let rupiahFormat = new Intl.NumberFormat('id-ID', {
    // style: 'currency',
    currency: 'IDR',
  }).format(number);

  return rupiahFormat
}

export const checkUserToken = () => {
  const userToken = localStorage.getItem('token');
  if (!userToken || userToken === 'undefined') {
    return false
  }else{
    return true
  }
}

export const isWeekday = (date) => {
  if(moment(date).isoWeekday() !== 6 && moment(date).isoWeekday() !== 7){
    return true
  }else{
    return false
  }
}