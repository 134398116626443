import React, {useEffect, useRef, useState} from 'react'
import QRCode from 'react-qr-code';
import {useLocation} from "react-router-dom"
import {priceSplitter} from '../../Tools/Tools'

function PaymentQris(){
  const location = useLocation()
  const qr_id = useRef(location.state.qr_id)
  const qr_string = useRef(location.state.qr_string)
  const expectedAmount = useRef(location.state.expectedAmount)
  const eventName = useRef(location.state.event_name)
  const ticketName = useRef(location.state.ticket_name)
  const topikName = useRef(location.state.topik_name)
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  useEffect(() => {
    setTimeout(function(){ 
      setLoaderDisplay('none')
    
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 1000)
  },[])

  return (
    <div id="wrapper" style={{'min-height' : '100vh'}}>
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content" style={{minHeight : '100vh'}}>
        <section className="background_header bg-primary" style={{'height' : 'auto'}}>
          <div className="npPage_SuccessPkg p-5">
            <div className="txt">
              <span className="d-block size-16 color-white font-weight-bolder">Selesaikan Segera Pembayaranmu Melalui</span>
              <div className="logo-payment bg-transparent rounded-10 py-2 my-3 w-50">
                <img src="/assets/img/qris/qris-logo.svg" className="img-filter-invert" alt="" width={'80%'} height={'auto'}/>
              </div>
              <span className="d-block size-16 color-white font-weight-bolder">QRIS CODE</span>
            </div>
          </div>
        </section>
        <section className="padding-20">
          <div className="title_sm pb-3">
            <span>Detail Pembayaran</span>
          </div>
          <div className="emPage__basket npPkg__basket justBoxes">
            <div className="itemPkg_cart">
              <div className="sideleft">
                <div>
                  <p>Tiket {ticketName.current}</p>
                  <h3>{eventName.current}</h3>
                </div>
              </div>
              <div className="price">
                <span className="size-13 color-primary">{topikName.current}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="padding-20 pt-0">
          <div className="npPkg__paymentDetails">
            <ul>
              <li>
                <span>Total Pembayaran</span>
                <span className="weight-600"><span className="color-primary">Rp {priceSplitter(expectedAmount.current)}</span></span>
              </li>
            </ul>
          </div>
        </section>
        <section className="padding-20 pt-0 padding-b-100">
          <div className="d-flex flex-column align-items-center npPkg__paymentDetails text-white text-center bg-primary border-0 py-4 pb-5 mb-4">
            <p className="w-75">Scan code QR dibawah untuk melakukan pembayaran</p>
            <div className="padding-10 bg-white w-75 rounded-10">
              <QRCode
                title={qr_id.current}
                value={qr_string.current}
                size='100%'
              />
            </div>
          </div>
        </section>
      </div>

      <div className="buttons__footer" style={{bottom : 0}}>
        <div className="env-pb text-center">
          <a 
            href="/home"
            className="btn bg-primary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder" 
            // style={{backgroundColor : '#c58fff'}}
          >
            Pesan Tiket Lainnya
          </a>
        </div>
        <div className="env-pb text-center">
          <a 
            href="/order"
            className="btn btn-secondary rounded-10 w-100 size-14 color-white text-center justify-content-center px-5 mb-2 font-weight-bolder" 
            // style={{backgroundColor : '#c58fff'}}
          >
            Lihat Daftar Pesanan
          </a>
        </div>
      </div>
    </div>
  )
}

export default PaymentQris;