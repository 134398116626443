import React, {useState, useEffect, useRef} from 'react'
import { useNavigate} from "react-router-dom"
import {forgotPassword, register} from '../../Api/Auth'

function ForgotPassword(){
  const navigate = useNavigate()
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const btnForgot = useRef(null)
  const btnForgotLoading = useRef(null)
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const inputRef = useRef(null);

  const formForgotHandler = async (e) => {
    e.preventDefault()
    setMessage("")
    btnForgot.current.className = 'd-none'
    btnForgotLoading.current.className = 'd-block'
    setTimeout(function() { 
      const formData = new FormData()
      formData.append('email', email);
      forgotPassword(formData).then((res) => {
        if(res.responseCode === 200000){
          setMessage(res.responseMessage+'. You will be redirected to Login Page in a few seconds')
          setTimeout(function() { 
            window.location.replace('/login');
          }, 5000)
        }else{
          setMessage(res.message)
          inputRef.current.focus()
          btnForgot.current.className = 'd-block'
          btnForgotLoading.current.className = 'd-none'
        }
      })
    }, 1000)
  }

  useEffect(function() {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    inputRef.current.focus()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="login">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Login</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              {/* title here.. */}
            </span>
          </div>
          {/* <div className="em_side_right">
            <a href="page-signin-email.html" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
              Need some help?
            </a>
          </div> */}
        </header>
        {/* End.main_haeder */}
        {/* Start em__signTypeOne */}
        <section className="em__signTypeOne typeTwo np__account padding-t-70">
          <div className="em_titleSign">
            <h1>Forgot your password!</h1>
            <p>
              If you need help resetting your password we can help by sending you a link to reset it.
            </p>
          </div>
          <div className="em__body">
            <form onSubmit={formForgotHandler} className="padding-t-40">
              <div className>
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input ref={inputRef} type="email" className="form-control" id="email" placeholder="example@mail.com" onChange={(e) => setEmail(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Message" transform="translate(2.248 2.614)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label htmlFor="email">Email Address</label>
                </div>
              </div>
              <div className="alert alert-light text-center" role="alert">
                {
                  message !== "" ? (<div>{message}</div>) : ''
                }
              </div>
              <div className="buttons__footer text-center bg-transparent">
                <div ref={btnForgot} className="d-block">
                  <button type="submit" className="btn bg-primary rounded-pill btn__default">
                    <span className="color-white">Send link</span>
                    <div className="icon rounded-circle">
                      <i className="tio-chevron_right" />
                    </div>
                  </button>
                </div>
                <div ref={btnForgotLoading} className="d-none">
                  <button type="button" className="btn bg-primary rounded-pill btn__default color-white" disabled>
                    <span className="color-white">Send link</span>
                    <div className="icon rounded-circle">
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
        {/* End. em__signTypeOne */}
      </div>
    </div>
  )
}

export default ForgotPassword;