import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation} from "react-router-dom"
import {productList, formatEvent} from '../../Api/Product'
import {priceSplitter} from '../../Tools/Tools'

function PaymentSuccess(){
  const location = useLocation()
  const navigate = useNavigate()
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const [loaderDisplay, setLoaderDisplay] = useState("none")
  const [displayLoadSpinner, setDisplayLoadSpinner] = useState("flex")
  const [formatEventList, setFormatEventList] = useState([])
  const [formatSelected, setFormatSelected] = useState(null)
  const [productEvent, setProductEvent] = useState([])
  const [viewEvent, setViewEvent] = useState()
  const [keywordSearch, setKeywordSearch] = useState()
  const [tmpKeywordSearch, setTmpKeywordSearch] = useState()
  const alertDisplaySearch = useRef(null)
  const infoDisplaySearch = useRef(null)

  const reqFormatEvent = async () => {
    formatEvent().then((res) => {
      if(res.status === 200){
        setFormatEventList(res.data)
        reqProduct()
      }
    })
  }

  const reqProduct = async (format = 'all') => {
    productList(format).then((res) => {
      if(res.status === 200){
        setDisplayLoadSpinner('none')
        setProductEvent(res.data)
      }
    })
  }
  
  const formatHandler = (format, key) => {
    setViewEvent(null)
    setDisplayLoadSpinner('flex')
    reqProduct(format)
    setFormatSelected(key)
  }
  
  const toOrderPage = () => {
    navigate('/order/completed')
  }
  
  const toProductDetail = (id) => {
    navigate('/product-detail', {state:{productId:id}})
  }

  useEffect(() => {
    setViewEvent(
      productEvent.map((event, Key) => {
        return (
          // <div key={Key} className="left__side" style={{minWidth : '45%', maxWidth : '45%'}}>
          <div key={Key} className={productEvent.length > 1 ? 'col-6' : 'col-10'}>
            <div onClick={() => toProductDetail(productEvent[Key].id_event)} className="item__card shadow-sm">
              <div className="cover_img">
                <img src={imageUrl+productEvent[Key].foto_banner} alt="" />
              </div>
              <div className="card__body p-2">
                <div>
                  <span className="mb-1" style={{fontSize:'11px'}}>
                    {productEvent[Key].start_date === productEvent[Key].end_date ? productEvent[Key].start_date : productEvent[Key].start_date+' - '+productEvent[Key].end_date}
                  </span>
                </div>
                <div className="mb-1 h-40 d-flex align-items-start">
                  <h5 className="size-14" style={{'color' : '#8821f6', height : 'auto'}}>
                    {productEvent[Key].event_name}
                  </h5>
                </div>
                <span className="mb-1 color-text size-12">
                {productEvent[Key].kota}
                </span>
                <h5 className="text-danger mb-1 size-14">
                  <span className="size-10 d-inline mr-1">Start</span>Rp {priceSplitter(productEvent[Key].start_price === null ? 0 : productEvent[Key].start_price)}
                </h5>
                <span className="mb-1 color-text size-10 mt-0">
                  <img src="assets/star.png" width={'auto'} height={12} alt="" className="mr-1"/>
                  Poin 
                  <span className="ml-1 text-primary size-10 d-inline">{productEvent[Key].poin_reward}</span>
                </span>
              </div>
            </div>
          </div>
        )
      })
    )
  },[productEvent])

  useEffect(() => {
    reqFormatEvent()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <section className="background_header bg-primary" style={{'height' : 'auto'}}>
          <div className="npPage_SuccessPkg p-4">
            <div className="txt">
              <svg className="w-36 h-36 text-gray-800 color-white mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <span className="d-block mb-2 size-18 color-white font-weight-bolder">Selamat! pembayaran kamu sudah berhasil.</span>
              <p classname="size-12d-block">
                Informasi order dan E-ticket akan dikirimkan ke alamat email dan tersimpan di halaman <b>Your Order</b>.
              </p>
              <button className="btn btn-sm bg-white color-primary my-3" onClick={(() => toOrderPage())}>Lihat Daftar Pesanan</button>
            </div>
          </div>
        </section>
        <section className="padding-20 overflow-auto bg-white" style={{'height' : 'auto'}}>
          <div className="title_sm pb-3">
            <h5 className="size-16 mb-1">Temukan tiket & event seru lainnya.</h5>
          </div>
          <div className="bg-white">
            <div>
              <div className="emTitle_co padding-b-10">
                <button 
                  className={
                    formatSelected === null ? 
                    'btn btn-primary btn-sm rounded-10 size-14 mb-2 mr-1 px-2' : 
                    'btn btn-outline-primary btn-sm rounded-10 size-14 mb-2 mr-1 px-2' 
                  }
                  style={{padding : '2px 5px 2px 5px'}}
                  onClick={() => {formatHandler('all', null)}}
                >
                  All
                </button>
                {
                  formatEventList.map((format, Key) => {
                    return (
                      <button 
                        key={Key}
                        className={
                          formatSelected === Key ? 
                          'btn btn-primary btn-sm rounded-10 size-14 mb-2 mr-1 px-2' : 
                          'btn btn-outline-primary btn-sm rounded-10 size-14 mb-2 mr-1 px-2' 
                        }
                        style={{padding : '2px 5px 2px 5px'}} 
                        onClick={() => {formatHandler(formatEventList[Key].id_product_event_format, Key)}}
                      >
                        {formatEventList[Key].format_name}
                      </button>)
                  })
                }
                <p className="size-12 color-text m-0">
                  Menampilkan 
                  <span className="font-weight-bolder d-inline"> {productEvent.length} </span> 
                  Data 
                  <span ref={infoDisplaySearch} className="d-none">
                  untuk kata kunci pencarian <b>"{tmpKeywordSearch}"</b>
                  </span>
                </p>
              </div>
              <div className="w-100 justify-content-center" style={{display : displayLoadSpinner}}>
                <div className="spinner_loading">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              </div>
              <div className="emPage__blog px-0 d-flex flex-wrap">
                <div ref={alertDisplaySearch} className="d-none">
                  <p className="color-text size-12 text-left">Tidak ada data ditemukan untuk kata kunci pencarian <b>"{keywordSearch}"</b>.</p>
                </div>
                <div className="row">
                {viewEvent}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="modal transition-bottom -inside screenFull defaultModal mdlladd__rate fade" id="mdl_copy" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
        <div className="modal-dialog">
          <div className="modal-content rounded-5">
            <div className="modal-body rounded-5 py-2" style={{backgroundColor: "rgb(52 58 64 / 7%)"}}>
              <div className="d-flex justify-content-center color-text size-12">
                <span>Copied to clipboard.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess