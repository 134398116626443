// import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {logout} from './Api/Auth';
import Page404 from './Pages/PageNotFound';
import SplashScreen from './Pages/SplashScreen';
import Home from './Pages/Homepage';
import Login from './Pages/Login';
import Register from './Pages/Register';
import RegisterVerification from './Pages/RegisterVerification';

// import Profile from './Pages/Profile/MyProfile';
import Profile from './Pages/Profile/Profile';
import PersonalDetails from './Pages/Profile/PersonalDetails';
import ChangePassword from './Pages/Profile/ChangePassword';
import ForgotPassword from './Pages/Profile/ForgotPassword';
import ResetPassword from './Pages/Profile/ResetPassword';

import ProductDetail from './Pages/Transaction/ProductDetail';
import Checkout from './Pages/Transaction/Checkout';
import Payment from './Pages/Transaction/Payment';
import PaymentQris from './Pages/Transaction/PaymentQris';
import PaymentEwallet from './Pages/Transaction/PaymentEwallet';
import MyOrder from './Pages/Order/MyOrder';
import History from './Pages/Order/History';
import Product from './Pages/Transaction/Product';
import DetailOrder from './Pages/Order/DetailOrder';
import Eticket from './Pages/Order/Eticket';
import PaymentSuccess from './Pages/Transaction/PaymentSuccess';
import Promo from './Pages/Others/Promo';
import PrivacyPolice from './Pages/Others/PrivacyPolicy';
import TermAndCondition from './Pages/Others/TermAndCondition';
import About from './Pages/Others/About';
import RegisterSport from './Pages/Transaction/RegisterSport';
import RegisterSportCheckout from './Pages/Transaction/RegisterSportCheckout';

import ProductWisataDetail from './Pages/Transaction/ProductWisataDetail';
import CheckoutWisata from './Pages/Transaction/CheckoutWisata';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Page404/>}></Route>
        <Route path="/" exact element={<SplashScreen/>}></Route>
        <Route path="/home" exact element={<Home/>}></Route>
        <Route path="/login" exact element={<Login/>}></Route>
        <Route path="/logout" onEnter={() => logout} ></Route>
        <Route path="/register" exact element={<Register/>}></Route>
        <Route path="/register-verification" exact element={<RegisterVerification/>}></Route>
        <Route path="/profile" element={<Profile/>}></Route>
        <Route path="/personal-details" element={<PersonalDetails/>}></Route>
        <Route path="/change-password" element={<ChangePassword/>}></Route>
        <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
        <Route path="/reset-password/:code" element={<ResetPassword/>}></Route>

        <Route path="/product" element={<Product/>}></Route>
        {/* <Route path="/product-detail/:id" element={<ProductDetail/>}></Route> */}
        <Route path="/product-detail/:slug" element={<ProductDetail/>}></Route>
        <Route path="/checkout" element={<Checkout/>}></Route>
        <Route path="/payment" element={<Payment/>}></Route>
        <Route path="/payment-qris" element={<PaymentQris/>}></Route>
        <Route path="/payment-ewallet" element={<PaymentEwallet/>}></Route>
        <Route path="/payment-success" element={<PaymentSuccess/>}></Route>
        <Route path="/order/:page?" element={<MyOrder/>}></Route>
        <Route path="/detail-order" element={<DetailOrder/>}></Route>
        <Route path="/eticket" element={<Eticket/>}></Route>
        <Route path="/history" element={<History/>}></Route>

        <Route path="/product-wisata-detail/:slug" element={<ProductWisataDetail/>}></Route>
        <Route path="/checkout-wisata" element={<CheckoutWisata/>}></Route>

        <Route path="/register-sport" element={<RegisterSport/>}></Route>
        <Route path="/register-sport-checkout" element={<RegisterSportCheckout/>}></Route>

        <Route path="/promo" element={<Promo/>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolice/>}></Route>
        <Route path="/term-condition" element={<TermAndCondition/>}></Route>
        <Route path="/about" element={<About/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
