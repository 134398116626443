import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {personalData, updateProfile} from '../../Api/Auth'

function PersonalDetails(){
  const navigate = useNavigate()
  // const userId = useRef(location.state.userId)

  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")
  const [validation, setValidation] = useState("")

  const inputRef = useRef(null);
  const [idUser, setIdUser] = useState("")
  const [fullname, setFullname] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  const getPersonalData = async () => {
    const token = localStorage.getItem('token')
    personalData(token).then((res) => {
      setIdUser(res.responseData.id_users)
      setFullname(res.responseData.fullname)
      setPhoneNumber(res.responseData.phone_number)
      setEmail(res.responseData.email)
    })
  }

  const updateHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('fullname', fullname);
      formData.append('phone_number', phoneNumber);
      formData.append('email', email);
      updateProfile(formData).then((res) => {
        localStorage.setItem('fullname', res.responseData.fullname);
        localStorage.setItem('email', res.responseData.email);
        localStorage.setItem('phone_number', res.responseData.phone_number);

        setTimeout(function() { 
          setValidation('')
          navigate('/personal-details')
        }, 1000)

        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  useEffect(() => {
    checkUserToken()
    getPersonalData()
    inputRef.current.focus()
  },[]);

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner-grow bg-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </section>
      <div id="content">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/profile">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Update Profile
            </span>
          </div>
          {/* <div className="em_side_right">
            <a href="/home" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all text-primary">
              Home
            </a>
          </div> */}
        </header>
        {/* form */}
        <section className="padding-20">
          <form onSubmit={updateHandler} className="padding-t-70">
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input ref={inputRef} type="text" className="form-control" defaultValue="Calvin Bell" id="fullname" placeholder="Enter full name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Profile" data-name="Iconly/Curved/Profile" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                      <g id="Profile" transform="translate(5 2.4)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M6.845,7.3C3.153,7.3,0,6.726,0,4.425S3.133,0,6.845,0c3.692,0,6.845,2.1,6.845,4.4S10.556,7.3,6.845,7.3Z" transform="translate(0 11.962)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M4.387,8.774a4.372,4.372,0,1,0-.031,0Z" transform="translate(2.45 0)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label htmlFor="fullname">Full name</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input type="email" className="form-control" defaultValue="orinostudio@gmail.com" id="email" placeholder="example@mail.com" value={email} onChange={(e) => setEmail(e.target.value)}  required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Message" transform="translate(2.248 2.614)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label htmlFor="email">Email Address</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div id="input_phone_number" className="input_group">
                  <input type="number" min="1" className="form-control ml-1" id="phonenumber" placeholder="0812xxxxxxx" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                  <div className="icon color-text">
                    {/* <span className="size-16">+62</span> */}
                    <svg className="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.693 16.013H7.31a1.685 1.685 0 0 0 1.685-1.684v-.645A1.684 1.684 0 0 1 10.679 12h2.647a1.686 1.686 0 0 1 1.686 1.686v.646c0 .446.178.875.494 1.19.316.317.693.495 1.14.495h1.685a1.556 1.556 0 0 0 1.597-1.016c.078-.214.107-.776.088-1.002.014-4.415-3.571-6.003-8-6.004-4.427 0-8.014 1.585-8.01 5.996-.02.227.009.79.087 1.003a1.558 1.558 0 0 0 1.6 1.02Z" />
                    </svg>
                  </div>
                </div>
                <label htmlFor="phonenumber">Phone Number</label>
              </div>
            </div>
            <div className="alert alert-light text-center" role="alert">
              {
                validation !== "" ? (<div>{validation}</div>) : ''
              }
            </div>
            <div className="buttons__footer text-center bg-transparent">
              <div className="env-pb">
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Save Changes</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </section>
        {/* buttons__footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default PersonalDetails;